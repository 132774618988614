import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppZustand } from '../shared/interfaces/app-zustand';
import { WebuchungZustand } from './interfaces/webuchung-zustand';
import { webuchungFeatureName } from './awtwe.reducer';

export const waehleBewegungsbuchungFeature = createFeatureSelector<AppZustand, WebuchungZustand>(webuchungFeatureName);

export const waehleBewegungsbuchungErgebnis = createSelector(
  waehleBewegungsbuchungFeature,
  zustand => zustand.webuchungErgebnis
);

export const waehleImZugriff = createSelector(
  waehleBewegungsbuchungFeature,
  zustand => zustand.imZugriff
);
