import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppZustand } from '../shared/interfaces/app-zustand';
import { KonfigurationZustand } from '../shared/interfaces/konfiguration-zustand';
import { konfigurationFeatureName } from './konfiguration.reducer';

export const waehleKonfigurationFeature = createFeatureSelector<AppZustand, KonfigurationZustand>(konfigurationFeatureName);

export const waehleVerbrauchsbuchungKonfiguration = createSelector(
  waehleKonfigurationFeature,
  zustand => zustand.konfiguration.verbrauchsbuchung
);

