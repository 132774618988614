import { Injectable } from '@angular/core';
import { SharedModule } from '../shared.module';

@Injectable({
  providedIn: SharedModule
})
export class UrlService {
  constructor() { }

  erstelleUrl(basisUrl: string, pfad: string): string {
    console.log (`url.service.ts erstelleURL: ${basisUrl.replace(/^(.+[^\/])\/+$/gi, '$1')}/${pfad.replace(/^\/+([^\/].+)$/gi, '$1')}`);

    return `${basisUrl.replace(/^(.+[^\/])\/+$/gi, '$1')}/${pfad.replace(/^\/+([^\/].+)$/gi, '$1')}`;
  }
}
