import { createReducer, on } from '@ngrx/store';
import { BewegungsbuchungErgebnis } from './interfaces/bewegungsbuchung-ergebnis';
import { BewegungsbuchungZustand } from './interfaces/bewegungsbuchung-zustand';
import { ladeInitialZustand, starteBewegungsbuchung, bewegungsBuchungAbgeschlossen } from './awteinlagern.actions';
import { backendNichtErreichbar, unerwarteterBackendFehler } from '../backend-zugriff/backend-zugriff.actions';

export const bewegungsbuchungFeatureName = 'einlagerbuchung';

export const initialZustand: BewegungsbuchungZustand = {
  bewegungsbuchungErgebnis: {
    erfolgreichDurchgefuehrt: false,
    spFehlerStatus: null,
    spFehlermeldung: null
  },
  imZugriff: false
};

function onStarteBewegungsbuchung(zustand: BewegungsbuchungZustand): BewegungsbuchungZustand {
  // console.log ("onStarteBewegungsbuchung");

  return {
    ...initialZustand,
    imZugriff: true
  };
}

function onBewegungsbuchungAbgeschlossen(zustand: BewegungsbuchungZustand, actionDaten: BewegungsbuchungErgebnis): BewegungsbuchungZustand {
  // console.log ("awteinlagern.reducer onBewegungsbuchungAbgeschlossen");

  return {
    ...zustand,
    bewegungsbuchungErgebnis: {
      ...actionDaten,
      erfolgreichDurchgefuehrt: !actionDaten.spFehlerStatus
    },
    imZugriff: false
  };
}

function onLadeInitialZustand(zustand: BewegungsbuchungZustand): BewegungsbuchungZustand {
  return initialZustand;
}

function loescheImZugriffFlags(zustand: BewegungsbuchungZustand): BewegungsbuchungZustand {
  return {
    ...zustand,
    imZugriff: false
  };
}

const reducer = createReducer(initialZustand,
  on(starteBewegungsbuchung, onStarteBewegungsbuchung),
  on(bewegungsBuchungAbgeschlossen, onBewegungsbuchungAbgeschlossen),
  on(ladeInitialZustand, onLadeInitialZustand),
  on(backendNichtErreichbar, loescheImZugriffFlags),
  on(unerwarteterBackendFehler, loescheImZugriffFlags)
);

export function bewegungsbuchungReducer(zustand, actionDaten) {
  // console.log ("awteinlagern.reducer.ts bewegungsbuchungReducer erzeugt");
  return reducer(zustand, actionDaten);
}
