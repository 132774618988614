import { createReducer, on } from '@ngrx/store';
import { RadscanErgebnis } from './interfaces/radscan-ergebnis';
import { RadscanZustand } from './interfaces/radscan-zustand';
import { ladeInitialZustandRadscan, starteRadscan, radscanAbgeschlossen } from './radscan.actions';
import { backendNichtErreichbar, unerwarteterBackendFehler } from '../../backend-zugriff/backend-zugriff.actions';

export const radscanFeatureName = 'radscan';

export const initialZustand: RadscanZustand = {
  radscanErgebnis: {
    Radsatz:"", DatenVL:"", DatenVR:"", DatenHL:"", DatenHR:"",
    spFehlerStatus: null,
    spFehlermeldung: null
  },
  imZugriff: false
};

function onStarteRadscan(zustand: RadscanZustand): RadscanZustand {
  console.log("radscan.reducer onStarteRadscan");

  return {
    ...initialZustand,
    imZugriff: true
  };
}
/*
function onRadscanAbgeschlossen(zustand: RadscanZustand, actionDaten: RadscanErgebnis): RadscanZustand {
  console.log("radscan.reducer onRadscanAbgeschlossen" );
  console.log(" Radsatz " + actionDaten.Radsatz + " VL: " + actionDaten.DatenVL );

  return {
    ...zustand,
    radscanErgebnis: {
      ...actionDaten,
      Radsatz:actionDaten.Radsatz, DatenVL:actionDaten.DatenVL, DatenVR:actionDaten.DatenVR, DatenHL:actionDaten.DatenHL, DatenHR:actionDaten.DatenHL    },
    imZugriff: false
  };
}
*/

function onRadscanAbgeschlossen(zustand: RadscanZustand, { ergebnis }: { ergebnis: RadscanErgebnis }): RadscanZustand {

  console.log("radscan.reducer onRadscanAbgeschlossen - hier nicht!");
  console.log(" Radsatz ", ergebnis.Radsatz, " VL: ", ergebnis.DatenVL);

  console.log (" 2024-08-01 " + ergebnis.DatenVL );

  return {
    ...zustand,
    radscanErgebnis: {
      ...ergebnis,
      Radsatz: ergebnis.Radsatz,
      DatenVL: ergebnis.DatenVL,
      DatenVR: ergebnis.DatenVR,
      DatenHL: ergebnis.DatenHL,
      DatenHR: ergebnis.DatenHR
    },
    imZugriff: false
  };
}

function onLadeInitialZustand(zustand: RadscanZustand): RadscanZustand {
  console.log("radscan.reducer onLadeInitialZustand");
  return initialZustand;
}

function loescheImZugriffFlags(zustand: RadscanZustand): RadscanZustand {
  return {
    ...zustand,
    imZugriff: false
  };
}
/*
const reducer = createReducer(initialZustand,
  on(starteRadscan, onStarteRadscan),
  on(radscanAbgeschlossen, onRadscanAbgeschlossen),
  on(ladeInitialZustandRadscan, onLadeInitialZustand),
  on(backendNichtErreichbar, loescheImZugriffFlags),
  on(unerwarteterBackendFehler, loescheImZugriffFlags)
);
*/


const radscanReducerInternal = createReducer(
  initialZustand,
  on(starteRadscan, (zustand) => {
    console.log("radscan.reducer onStarteRadscan");
    return {
      ...zustand,
      imZugriff: true
    };
  }),
  on(radscanAbgeschlossen, (zustand, { ergebnis }) => {
    console.log("radscan.reducer onRadscanAbgeschlossen - bei createReducer", ergebnis);
    return {
      ...zustand,
      radscanErgebnis: ergebnis,
      imZugriff: false
    };
  }),
  on(ladeInitialZustandRadscan, () => {
    console.log("radscan.reducer onLadeInitialZustand");
    return initialZustand;
  }),
  on(backendNichtErreichbar, (zustand) => ({
    ...zustand,
    imZugriff: false
  })),
  on(unerwarteterBackendFehler, (zustand) => ({
    ...zustand,
    imZugriff: false
  }))
);

export function radscanReducer(zustand, actionDaten) {
  console.log ("radscan.reducer radscanReducer");

  return radscanReducerInternal(zustand, actionDaten);
}
