import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutorisierungGuard } from '../backend-zugriff/autorisierung/autorisierung.guard';
import { AwtauslagernFormularComponent } from './awtauslagern-formular/awtauslagern-formular.component';

const routes: Routes = [
  { path: 'awtauslagern', component: AwtauslagernFormularComponent, canActivate: [AutorisierungGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AwtauslagernRoutingModule { }
