import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReifenherstellerRoutingModule } from './reifenhersteller-routing.module';
import { ReifenherstellerComponent } from './reifenhersteller.component';
import { FormsModule } from '@angular/forms';  // Importiere FormsModule hier


@NgModule({
  declarations: [ReifenherstellerComponent],
  imports: [
    CommonModule,
    ReifenherstellerRoutingModule,
    FormsModule
  ]
})
export class ReifenherstellerModule { }
