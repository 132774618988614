import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FelgenartComponent } from './felgenart.component';  // Importiere die FelgenartComponent

const routes: Routes = [
  { path: 'felgenart', component: FelgenartComponent },  // Definiere die Route für Felgenart
];

@NgModule({
  imports: [RouterModule.forChild(routes)],  // Verwende `forChild` für Feature-Routing
  exports: [RouterModule]
})
export class FelgenartRoutingModule { }
