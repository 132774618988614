import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutorisierungGuard } from '../backend-zugriff/autorisierung/autorisierung.guard';
import { AuslagernComponent } from './auslagern/auslagern.component';
import { HauptmenueComponent } from './hauptmenue/hauptmenue.component';
// import { AwtEinlagernComponent } from './awteinlagern/awteinlagern.component';


const routes: Routes = [
  {
    path: 'navigation',
    canActivate: [AutorisierungGuard],
    canActivateChild: [AutorisierungGuard],
    children: [
      { path: '', component: HauptmenueComponent, pathMatch: 'full' },
      { path: 'auslagern', component: AuslagernComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NavigationRoutingModule { }
