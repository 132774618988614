import { createReducer, on } from '@ngrx/store';
import { AuslagernErgebnis } from './interfaces/auslagern-ergebnis';
import { AuslagernZustand } from './interfaces/auslagern-zustand'; // PaletteinfoZustand
import { ladeInitialZustand, starteBewegungsbuchung, bewegungsBuchungAbgeschlossen, startePaletteInfoAnfrage, paletteinfoGeladen } from './awtauslagern.actions';
import { backendNichtErreichbar, unerwarteterBackendFehler } from '../backend-zugriff/backend-zugriff.actions';
// import { PaletteinfoZustand } from './interfaces/paletteinfo-zustand';
import { PaletteInfoErgebnis } from './interfaces/paletteinfo-ergebnis';
//import { PaletteinfoZustand } from './interfaces/__paletteinfo-zustand';

export const auslagenFeatureName = 'auslagerbuchung';

// export const paletteinfoFeatureName = 'paletteinfo';


export const initialZustand: AuslagernZustand = {
  auslagernErgebnis: {
    erfolgreichDurchgefuehrt: false,
    spFehlerStatus: null,
    spFehlermeldung: null
  },
  paletteinfoErgebnis: {
    auftrag: null,
    klbezeichnung: null,
    auslagerziel: null,
    kl_datum: null,
    auftrag2: null,
    klbezeichnung2: null,
    auslagerziel2: null,
    kl_datum2: null,
    spFehlerStatus: null,
    spFehlermeldung: null
  },
  imZugriff: false
};


/*
export const paletteInitialZustand: PaletteinfoZustand = {
  paletteinfoErgebnis: {
    auftrag: null,
    klbezeichnung: null,
    auslagerziel: null,
    spFehlerStatus: null,
    spFehlermeldung: null
  },
  imZugriff: false
}
*/

function onStarteBewegungsbuchung(zustand: AuslagernZustand): AuslagernZustand {
  // console.log ("onStarteBewegungsbuchung");

  return {
    ...initialZustand,
    imZugriff: true
  };
}

function onBewegungsbuchungAbgeschlossen(zustand: AuslagernZustand, actionDaten: AuslagernErgebnis): AuslagernZustand {
  // console.log ("awteinlagern.reducer onBewegungsbuchungAbgeschlossen");

  return {
    ...zustand,
    auslagernErgebnis: {
      ...actionDaten,
      erfolgreichDurchgefuehrt: !actionDaten.spFehlerStatus
    },
    imZugriff: false
  };
}

/*
function onPaletteInfoAbgeschlossen(zustand: AuslagernZustand, actionDaten: PaletteInfoErgebnis): AuslagernZustand {
  return {
    ...zustand,
    auslagernErgebnis: {
      ...actionDaten,
      /-* PaletteInfoErgebnis: {
        auftrag: actionDaten.auftrag;
        klbezeichnung: actionDaten.klbezeichnung;
        auslagerziel: strinactionDaten.auslagerziel;
      } *-/
      erfolgreichDurchgefuehrt: !actionDaten.spFehlerStatus
    },
    imZugriff: false
  };
}
*/

/*
function onPaletteInfoAbgeschlossen(zustand: PaletteinfoZustand, actionDaten: PaletteInfoErgebnis): PaletteinfoZustand {
  return {
    ...zustand,
    paletteinfoErgebnis: {
      ...actionDaten
    },
    imZugriff: false
  };
}
*/

function onLadeInitialZustand(zustand: AuslagernZustand): AuslagernZustand {
  return initialZustand;
}

function loescheImZugriffFlags(zustand: AuslagernZustand): AuslagernZustand {
  return {
    ...zustand,
    imZugriff: false
  };
}

const reducer = createReducer(initialZustand,
  on(starteBewegungsbuchung, onStarteBewegungsbuchung),
  on(bewegungsBuchungAbgeschlossen, onBewegungsbuchungAbgeschlossen),

  on(startePaletteInfoAnfrage, onStartePaletteInfoAbfrage),
  on(paletteinfoGeladen, onPaletteInfoAnfrageAbgeschlossen),

  on(ladeInitialZustand, onLadeInitialZustand),
  on(backendNichtErreichbar, loescheImZugriffFlags),
  on(unerwarteterBackendFehler, loescheImZugriffFlags)
);

//export const startePaletteInfoAnfrage = createAction('[Info] Starte PaletteInfo', props<PaletteInfoAnfrage>());
//export const paletteinfoGeladen = createAction('[Info] PaletteInfo geladen', props<PaletteInfoErgebnis>());

export function bewegungsbuchungReducer(zustand, actionDaten) {
  // console.log ("awteinlagern.reducer.ts bewegungsbuchungReducer erzeugt");
  return reducer(zustand, actionDaten);
}

export function paletteinfoReducer(zustand, actionDaten) {
  // console.log ("awteinlagern.reducer.ts paletteinfoReducer erzeugt");
  return reducer(zustand, actionDaten);
}



function onStartePaletteInfoAbfrage(zustand: AuslagernZustand): AuslagernZustand {
  console.log ("onStartePaletteInfoAbfrage");

  return {
    ...initialZustand,
    imZugriff: true
  };
}

function onPaletteInfoAnfrageAbgeschlossen(zustand: AuslagernZustand, actionDaten: PaletteInfoErgebnis ): AuslagernZustand {
  console.log ("onPaletteInfoAnfrageAbgeschlossen");
// debugger
  return {
    ...initialZustand,
    imZugriff: false ,
    paletteinfoErgebnis: {
      auftrag: actionDaten.auftrag,
      klbezeichnung: actionDaten.klbezeichnung,
      auslagerziel: actionDaten.auslagerziel,
      kl_datum: actionDaten.kl_datum,
      auftrag2: actionDaten.auftrag2,
      klbezeichnung2: actionDaten.klbezeichnung2,
      auslagerziel2: actionDaten.auslagerziel2,
      kl_datum2: actionDaten.kl_datum2,
      spFehlerStatus: null,
      spFehlermeldung: null
    }

  };
}
