import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppZustand } from '../shared/interfaces/app-zustand';
import { backendZugriffFeatureName } from './backend-zugriff.reducer';
import { BackendZugriffZustand } from './interfaces/backend-zugriff-zustand';

export const waehleBackendZugriffFeature = createFeatureSelector<AppZustand, BackendZugriffZustand>(backendZugriffFeatureName);

export const waehleFirmenbackendUrl = createSelector(
  waehleBackendZugriffFeature,
  zustand => zustand.firmenbackendUrl
);

export const waehleBenutzerAutorisierung = createSelector(
  waehleBackendZugriffFeature,
  zustand => zustand.benutzerAutorisierung
);

export const waehleBackendZugriffFehler = createSelector(
  waehleBackendZugriffFeature,
  zustand => zustand.backendZugriffFehler
);

export const waehleLoginImZugriff = createSelector(
  waehleBackendZugriffFeature,
  zustand => zustand.loginImZugriff
);

export const waehleLogoutImZugriff = createSelector(
  waehleBackendZugriffFeature,
  zustand => zustand.logoutImZugriff
);
