import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import { KonfigurationEffects } from './konfiguration.effects';
import { konfigurationFeatureName, konfigurationReducer } from './konfiguration.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature(konfigurationFeatureName, konfigurationReducer),
    EffectsModule.forFeature([KonfigurationEffects])
  ]
})
export class KonfigurationModule { }
