import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageFormularComponent } from './image-formular/image-formular.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { imageFeatureName, imageReducer } from './image.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ImageEffects } from './image.effects';
import { ImageRoutingModule } from './image-routing.module';

@NgModule({
  declarations: [ImageFormularComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    StoreModule.forFeature(imageFeatureName, imageReducer),
    EffectsModule.forFeature([ImageEffects]),
    ImageRoutingModule
  ]
})
export class ImageModule { }
