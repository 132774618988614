import { Component, Input } from '@angular/core';

@Component({
  selector: 'lagermanager-erfolg-symbol',
  templateUrl: './erfolg-symbol.component.html',
  styleUrls: ['./erfolg-symbol.component.scss']
})
export class ErfolgSymbolComponent {
  @Input('class') klassen = '';
}
