import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import { AktuellerBenutzerComponent } from './aktueller-benutzer/aktueller-benutzer.component';
import { AutorisierungService } from './autorisierung/autorisierung.service';
import { BackendFehlerAnzeigeComponent } from './backend-fehler-anzeige/backend-fehler-anzeige.component';
import { LoginRoutingModule } from './backend-zugriff-routing.module';
import { BackendZugriffEffects } from './backend-zugriff.effects';
import { backendZugriffFeatureName, backendZugriffReducer } from './backend-zugriff.reducer';
import { LoginFormularComponent } from './login-formular/login-formular.component';

@NgModule({
  declarations: [LoginFormularComponent, AktuellerBenutzerComponent, BackendFehlerAnzeigeComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forFeature(backendZugriffFeatureName, backendZugriffReducer),
    EffectsModule.forFeature([BackendZugriffEffects]),
    LoginRoutingModule
  ],
  exports: [
    AktuellerBenutzerComponent,
    BackendFehlerAnzeigeComponent
  ],
  providers: [
    AutorisierungService
  ]
})
export class BackendZugriffModule { }
