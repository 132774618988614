import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lagermanager-auslagern',
  templateUrl: './auslagern.component.html',
  styleUrls: ['./auslagern.component.scss']
})
export class AuslagernComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
