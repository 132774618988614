import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dimension',
  templateUrl: './dimension.component.html',
  styleUrls: ['./dimension.component.scss']
})
export class DimensionComponent implements OnInit {
  radsatznummer: string;
  reifenart: string;
  felgenart: string;
  reifenhersteller: string;
  reifenprofiltyp: string;
  dimensionen: string = '';

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    // Lese die Query-Params aus der Route
    this.route.queryParams.subscribe(params => {
      this.radsatznummer = params['radsatznummer'];
      this.reifenart = params['reifenart'];
      this.felgenart = params['felgenart'];
      this.reifenhersteller = params['reifenhersteller'];
      this.reifenprofiltyp = params['reifenprofiltyp'];
    });
  }

  // Optional: Methode zur Verarbeitung des DOT-Codes
  onSubmit(): void {
    console.log('Dimensionen:', this.dimensionen);
    console.log('Übergebene Parameter:', this.radsatznummer, this.reifenart, this.felgenart, this.reifenhersteller, this.reifenprofiltyp);
    // Weitere Logik zum Absenden des Formulars oder Navigation
  }

}
