import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AutorisierungService } from '../../backend-zugriff/autorisierung/autorisierung.service';
import { UrlService } from '../../shared/url-service/url.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BackendZugriffModule } from '../../backend-zugriff/backend-zugriff.module';

@Injectable({
  providedIn: 'root'
})
export class ReifenherstellerService {

  private apiUrl = "";

  //constructor(private http: HttpClient) {}
  constructor(private action$: Actions, private http: HttpClient, private urlService: UrlService, private autorisierungService: AutorisierungService) {
    console.log("reifenhersteller.service constructor");

    this.apiUrl = this.urlService.erstelleUrl(this.autorisierungService.firmenbackendUrl.firmenbackendUrl,
      'api/reifenhersteller');
  }
  // Methode, um die Liste der Reifenhersteller vom Backend zu laden
  getReifenhersteller(): Observable<string[]> {
    console.log("reifenhersteller.service getReifenhersteller() ");
    return this.http.get<string[]>(this.apiUrl);  // Annahme: Backend gibt eine Liste von Strings zurück
  }


}
