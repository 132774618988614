import { createAction, props } from '@ngrx/store';
import { LagerortDetails } from './interfaces/lagerort-details';
import { LieferantDetails } from './interfaces/lieferant-details';
import { ProduktDetails } from './interfaces/produkt-details';
import { LieferantBarcodeDaten } from './interfaces/lieferant-barcode-daten';
import { LagerortBarcodeDaten } from './interfaces/lagerort-barcode-daten';
import { ProduktBarcodeDaten } from './interfaces/produkt-barcode-daten';
import { RadsatzBarcodeDaten } from './interfaces/radsatz-barcode-daten';

export const ladeLieferantdetailsZuBarcode = createAction('[BarcodeScan] Lade Lieferantdetails zu Barcode', props<LieferantBarcodeDaten>());
export const lieferantDetailsZuBarcodeGeladen = createAction('[BarcodeScan] Lieferantdetails zu Barcode geladen', props<LieferantDetails>());
export const ladeLagerortdetailsZuBarcode = createAction('[BarcodeScan] Lade Lagerortdetails zu Barcode', props<LagerortBarcodeDaten>());
export const lagerortdetailsZuBarcodeGeladen = createAction('[BarcodeScan] Lagerortdetails zu Barcode geladen', props<LagerortDetails>());
export const ladeProduktdetailsZuBarcode = createAction('[BarcodeScan] Lade Produktdetails zu Barcode', props<ProduktBarcodeDaten>());
export const produktdetailsZuBarcodeGeladen = createAction('[BarcodeScan] Produktdetails zu Barcode geladen', props<ProduktDetails>());
export const ladeInitialZustand = createAction('[BarcodeScan] Lade Initialzustand');
