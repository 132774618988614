import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BackendZugriffModule } from './backend-zugriff/backend-zugriff.module';
import { httpInterceptors } from './backend-zugriff/http-interceptors/http-interceptors';
import { KonfigurationModule } from './konfiguration/konfiguration.module';
import { NavigationModule } from './navigation/navigation.module';
import { VerbrauchsbuchungModule } from './verbrauchsbuchung/verbrauchsbuchung.module';
import { AwteinlagernModule } from './awteinlagern/awteinlagern.module';
import { AutofocusDirective } from './autofocus.directive';
import { AwtweModule } from './awtwe/awtwe.module';
import { AwtauslagernModule } from './awtauslagern/awtauslagern.module';

import { ImageModule } from './image/image.module';
import { AwtwaModule } from './awtwa/awtwa.module';
//import { AbholungComponent } from './abholung/abholung.component';
import { AbholungModule } from './abholung/abholung.module';

const zufall : Number = Math.random() * 99999999;


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/uebersetzungen/', '.json?i=' + zufall);
}

@NgModule({
  declarations: [
    AppComponent,
    AutofocusDirective
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    BackendZugriffModule,
    KonfigurationModule,
    NavigationModule,
    VerbrauchsbuchungModule,
    ImageModule,
    AwteinlagernModule,
    AwtweModule,
    AwtwaModule,
    AbholungModule,
    AwtauslagernModule,
    AppRoutingModule,
    NgbModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([])
  ],
  providers: [
    httpInterceptors
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
