import { createAction, props } from '@ngrx/store';
import { RadscanAnfrage } from './interfaces/radscan-anfrage';
import { RadscanErgebnis } from './interfaces/radscan-ergebnis';

export const starteRadscan = createAction('[Radscan] Starte Radscan', props< RadscanAnfrage >());
/* export const radscanAbgeschlossen = createAction('[Radscan] Radscan abgeschlosssen', props<RadscanErgebnis>()); */

export const radscanAbgeschlossen = createAction(
  '[Radscan] Radscan abgeschlossen',
  props<{ ergebnis: RadscanErgebnis }>()
);

export const ladeInitialZustandRadscan = createAction('[Radscan] Lade Initialzustand');
