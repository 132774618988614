import { createReducer, on } from '@ngrx/store';
import { backendNichtErreichbar, beginneLogin, ermittleFirmenbackendUrl, ermittleFirmenbackendUrlAbgeschlossen, loescheBackendFehler, loggeAus, loginBeendet, logoutBeendet, pruefeAnmeldedaten, pruefeAnmeldedatenAbgeschlossen, unerwarteterBackendFehler } from './backend-zugriff.actions';
import { BackendZugriffZustand } from './interfaces/backend-zugriff-zustand';
import { BenutzerAutorisierung } from './interfaces/benutzer-autorisierung';
import { FirmenbackendUrlErgebnis } from './interfaces/firmenbackend-url-ergebnis';

export const backendZugriffFeatureName = 'backendZugriff';

export const initialZustand: BackendZugriffZustand = {
  loginImZugriff: false,
  logoutImZugriff: false,
  firmenbackendUrl: {
    imZugriff: false,
    firmenbackendUrl: null,
    spFehlerStatus: null,
    spFehlermeldung: null
  },
  benutzerAutorisierung: {
    imZugriff: false,
    token: null,
    anzeigename: null,
    benutzernamePasswortInkorrekt: false
  },
  backendZugriffFehler: {
    backendNichtErreichbar: false,
    unerwarteterBackendFehler: false
  },
};

function onBeginneLogin(): BackendZugriffZustand {
  return {
    ...initialZustand,
    loginImZugriff: true
  };
}

function onLoginBeendet(zustand: BackendZugriffZustand): BackendZugriffZustand {
  return {
    ...zustand,
    loginImZugriff: false
  };
}

function onErmittleFirmenbackendUrl(zustand: BackendZugriffZustand): BackendZugriffZustand {
  return {
    ...zustand,
    firmenbackendUrl: {
      ...initialZustand.firmenbackendUrl,
      imZugriff: true
    }
  };
}

function onErmittleFirmenbackendUrlAbgeschlossen(zustand: BackendZugriffZustand, actionDaten: FirmenbackendUrlErgebnis): BackendZugriffZustand {
  return {
    ...zustand,
    ...initialZustand,
    firmenbackendUrl: actionDaten,
    loginImZugriff: !actionDaten.spFehlerStatus && !actionDaten.spFehlermeldung
  };
}

function onPruefeAnmeldedaten(zustand: BackendZugriffZustand): BackendZugriffZustand {
  return {
    ...zustand,
    benutzerAutorisierung: {
      ...initialZustand.benutzerAutorisierung,
      imZugriff: true
    }
  };
}

function onPruefeAnmeldedatenAbgeschlossen(zustand: BackendZugriffZustand, actionDaten: BenutzerAutorisierung): BackendZugriffZustand {
  return {
    ...zustand,
    benutzerAutorisierung: {
      ...zustand.benutzerAutorisierung,
      ...actionDaten
    },
    loginImZugriff: false
  };
}

function onBackendNichtErreichbar(zustand: BackendZugriffZustand): BackendZugriffZustand {
  return {
    ...zustand,
    firmenbackendUrl: {
      ...zustand.firmenbackendUrl,
      imZugriff: false
    },
    benutzerAutorisierung: {
      ...zustand.benutzerAutorisierung,
      imZugriff: false
    },
    backendZugriffFehler: {
      ...zustand.backendZugriffFehler,
      backendNichtErreichbar: true
    },
    loginImZugriff: false
  };
}

function onUnerwarteterBackendFehler(zustand: BackendZugriffZustand): BackendZugriffZustand {
  return {
    ...zustand,
    firmenbackendUrl: {
      ...zustand.firmenbackendUrl,
      imZugriff: false
    },
    benutzerAutorisierung: {
      ...zustand.benutzerAutorisierung,
      imZugriff: false
    },
    backendZugriffFehler: {
      ...zustand.backendZugriffFehler,
      unerwarteterBackendFehler: true
    },
    loginImZugriff: false
  };
}

function onLoescheBackendFehler(zustand: BackendZugriffZustand) : BackendZugriffZustand {
  return {
    ...zustand,
    backendZugriffFehler: initialZustand.backendZugriffFehler
  };
}

function onLoggeAus(zustand: BackendZugriffZustand): BackendZugriffZustand {
  return {
    ...zustand,
    logoutImZugriff: true
  };
}

function onLogoutBeendet() : BackendZugriffZustand {
  return initialZustand;
}

const reducer = createReducer(initialZustand,
  on(beginneLogin, onBeginneLogin),
  on(loginBeendet, onLoginBeendet),
  on(ermittleFirmenbackendUrl, onErmittleFirmenbackendUrl),
  on(ermittleFirmenbackendUrlAbgeschlossen, onErmittleFirmenbackendUrlAbgeschlossen),
  on(pruefeAnmeldedaten, onPruefeAnmeldedaten),
  on(pruefeAnmeldedatenAbgeschlossen, onPruefeAnmeldedatenAbgeschlossen),
  on(backendNichtErreichbar, onBackendNichtErreichbar),
  on(unerwarteterBackendFehler, onUnerwarteterBackendFehler),
  on(loescheBackendFehler, onLoescheBackendFehler),
  on(loggeAus, onLoggeAus),
  on(logoutBeendet, onLogoutBeendet));

export function backendZugriffReducer(zustand, action) {
  return reducer(zustand, action);
};
