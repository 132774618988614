import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ReifenartComponent } from './pruefen/reifenart/reifenart.component';
import { FelgenartComponent } from './pruefen/felgenart/felgenart.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full'},

  // Lazy loading für Reifenart
//  { path: 'reifenart', loadChildren: () => import('./pruefen/reifenart/reifenart.module').then(m => m.ReifenartModule) }

 // { path: 'reifenart/:radsatz', component: ReifenartComponent },  // Reifenart-Seite mit dem Parameter "radsatz"

 // { path: 'felgenart/', component: FelgenartComponent },  // Route für Felgenart

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
