import { Routes, RouterModule } from '@angular/router';
import { ImageFormularComponent } from './image-formular/image-formular.component';
import { AutorisierungGuard } from '../backend-zugriff/autorisierung/autorisierung.guard';
import { NgModule } from '@angular/core';
const routes: Routes = [
  { path: 'image', component: ImageFormularComponent, canActivate: [AutorisierungGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImageRoutingModule { }
