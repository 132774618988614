import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { UrlService } from '../shared/url-service/url.service';
import { AutorisierungService } from './autorisierung/autorisierung.service';
import { ermittleFirmenbackendUrl, ermittleFirmenbackendUrlAbgeschlossen, loggeAus, logoutBeendet, pruefeAnmeldedaten, pruefeAnmeldedatenAbgeschlossen } from './backend-zugriff.actions';
import { AnmeldedatenMitFirmenbackendUrl } from './interfaces/anmeldedaten-mit-firmenbackend-url';
import { BenutzerAutorisierung } from './interfaces/benutzer-autorisierung';
import { FirmenbackendUrlAnfrage } from './interfaces/firmenbackend-url-anfrage';
import { FirmenbackendUrlErgebnis } from './interfaces/firmenbackend-url-ergebnis';

@Injectable()
export class BackendZugriffEffects {
  ermittleFirmenbackendUrl$ = createEffect(() => this.action$.pipe(
    ofType(ermittleFirmenbackendUrl.type),
    mergeMap((actionDaten: FirmenbackendUrlAnfrage) => {
      return this.http.post<FirmenbackendUrlErgebnis>('api/firmenbackendUrl', {
        firmenkuerzel: actionDaten.firmenkuerzel
      }).pipe(
        map(ergebnisse => ermittleFirmenbackendUrlAbgeschlossen(ergebnisse))
      )
    })
  ));

  pruefeAnmeldedaten$ = createEffect(() => this.action$.pipe(
    ofType(pruefeAnmeldedaten.type),
    mergeMap((actionDaten: AnmeldedatenMitFirmenbackendUrl) => {
      return this.http.post<BenutzerAutorisierung>(this.urlService.erstelleUrl(actionDaten.firmenbackendUrl.firmenbackendUrl, 'api/login'), {
        Benutzername: actionDaten.anmeldedaten.benutzername,
        Passwort: actionDaten.anmeldedaten.passwort
      }).pipe(
        map(ergebnisse => pruefeAnmeldedatenAbgeschlossen(ergebnisse))
      );
    })
  ));

  loggeAus$ = createEffect(() => this.action$.pipe(
    ofType(loggeAus.type),
    filter(() => this.autorisierungService.istEingeloggt),
    mergeMap(() => {
      return this.http.post(this.urlService.erstelleUrl(this.autorisierungService.firmenbackendUrl.firmenbackendUrl, 'api/login/loggeAus'), {
        Token: this.autorisierungService.benutzerAutorisierung.token
      }).pipe(
        map(() => logoutBeendet()),
        catchError(() => of(logoutBeendet()))
      );
    })
  ));

  constructor(private action$: Actions, private http: HttpClient, private urlService: UrlService, private autorisierungService: AutorisierungService) {

  }
}
