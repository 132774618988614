import { Component, OnDestroy, OnInit, ViewChild, ElementRef, AfterViewInit  } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { lagerortBarcodeInhaltsformat, lieferantBarcodeInhaltsformat, produktBarcodeInhaltsformat } from 'src/app/shared/konstanten';
import { AutorisierungService } from '../../backend-zugriff/autorisierung/autorisierung.service';
import { ladeInitialZustand as ladeInitialZustandBarcodeScan } from '../../barcode-scan/barcode-scan.actions';
// Barcode-SCAN: import { waehleLagerortDetails, waehleLagerortImZugriff, waehleLieferantDetails,
//    waehleLieferantImZugriff, waehleProduktDetails, waehleProduktImZugriff } from '../../barcode-scan/barcode-scan.selectors';
import { BarcodeVerarbeitungService } from '../../barcode-scan/barcode-verarbeitung.service';
// import { LagerortDetails } from '../../barcode-scan/interfaces/lagerort-details';
// import { LieferantDetails } from '../../barcode-scan/interfaces/lieferant-details';

import { ScanFormularComponent } from '../../barcode-scan/scan-formular/scan-formular.component';
import { KonfigurationService } from '../../konfiguration/konfiguration.service';
import { AppZustand } from '../../shared/interfaces/app-zustand';
import { datenBekanntValidierer } from '../../shared/validierer/daten-bekannt-validierer';
import { zahlValidierer } from '../../shared/validierer/zahl-validierer';

import { AuslagernAnfrage } from '../interfaces/auslagern-anfrage';
import { AuslagernErgebnis } from '../interfaces/auslagern-ergebnis';
import { AuslagernZustand } from '../interfaces/auslagern-zustand';

import { PaletteInfoAnfrage } from '../interfaces/paletteinfo-anfrage';
import { PaletteInfoErgebnis } from '../interfaces/paletteinfo-ergebnis';
// import { PaletteinfoZustand } from '../interfaces/paletteinfo-zustand';

import { ladeInitialZustand as ladeInitialZustandBewegungsbuchung, starteBewegungsbuchung, startePaletteInfoAnfrage } from '../awtauslagern.actions';
import { waehleImZugriff as waehleBewegungsbuchungImZugriff, waehleAuslagernFeature
    , waehlePaletteinfoFeature /*, waehlePaletteinfoErgebnis, waehlePaletteinfoFeature */ } from '../awtauslagern.selectors';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-awtauslagern-formular',
  templateUrl: './awtauslagern-formular.component.html',
  styleUrls: ['./awtauslagern-formular.component.scss']
})
export class AwtauslagernFormularComponent implements OnInit {

  private observableBeobachter: Subscription[] = [];

  @ViewChild(ScanFormularComponent, { static: false })
  private scanFormularComponent: ScanFormularComponent;

  @ViewChild('meldungen', { static: false })
  private meldungen;

  @ViewChild('focusPalette', { static: false }) focusPalette: ElementRef;
  @ViewChild('focusRad', { static: false }) focusRad: ElementRef;

  @ViewChild('focusPalInfo', { static: false }) focusPalInfo: ElementRef;


  get istScanAktiv() {
    return this.scanFormularComponent && this.scanFormularComponent.istScanAktiv;
  }

  bewegungsbuchungImZugriff: boolean;
  private bewegungsbuchungErgebnisDaten: AuslagernErgebnis = {
    erfolgreichDurchgefuehrt: false,
    spFehlerStatus: null,
    spFehlermeldung: null
  };


  get bewegungsbuchungErgebnis() {
    return this.bewegungsbuchungErgebnisDaten;
  }


  formular: FormGroup;

  private paletteinfoDetails: PaletteInfoErgebnis;

  get palette() {
    return this.formular.get('palette');
  }

  get palInfo() {
    return this.formular.get('palInfo');
  }

  get rad() {
    return this.formular.get('rad');
  }



  constructor(private barcodeVerarbeitungService: BarcodeVerarbeitungService, private formBuilder: FormBuilder, private store: Store<AppZustand>,
    private konfigurationService: KonfigurationService, private autorisierungService: AutorisierungService, private modalService: NgbModal,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    // hier ergänzen wenn neue Felder mit formControlName aufgenommen werden
    this.formular = this.formBuilder.group({
      rad: new FormControl(''),
      palette: new FormControl(''),
      palInfo: new FormControl('')
    });

    // startePaletteInfoAnfrage

    // let paletteinfoDetails$ = this.store.select(waehlePaletteinfoDetails);
//    let lagerortDetails$ = this.store.select(waehleLagerortDetails);


    let BewegungsbuchungImZugriff$ = this.store.select(waehleBewegungsbuchungImZugriff);
    let BewegungsbuchungFeature$ = this.store.select(waehleAuslagernFeature);

    let PaletteinfoFeature$ = this.store.select(waehlePaletteinfoFeature);

    this.observableBeobachter.push(
      BewegungsbuchungFeature$.subscribe(ergebnis => this.verarbeiteBewegungsbuchungFeature(ergebnis))
    );

    this.observableBeobachter.push(
      BewegungsbuchungImZugriff$.subscribe(ergebnis => {
        this.aktiviereFormular(ergebnis);
      })
    );

    this.observableBeobachter.push(
      PaletteinfoFeature$.subscribe(ergebnis => this.verarbeitePaletteDetails(ergebnis))
    );

  }


  ngAfterViewInit() { // Focus in erstes Feld setzen
    this.focusPalette.nativeElement.focus();
  }


  fokussiereRad_palinfo(event) {
    // console.log("awteinlagern-formular.component.ts fokussierePalette");
    event.preventDefault();

    //this.paletteinfo.value = "1234 auslagern\n5678 --";


    // DB Anfrage Info Palette
    let anfrage: PaletteInfoAnfrage = {
      token: this.autorisierungService.benutzerAutorisierung.token,
      barcode: this.palette.value // barcode=palette
    };

    this.store.dispatch(startePaletteInfoAnfrage(anfrage));

    // fuer naechsten scan Focus auf Rad Feld stellen
    this.focusRad.nativeElement.focus();
    return false;
  }


  private leereBewegungsbuchungErgebnis(): void {
    this.bewegungsbuchungErgebnis.erfolgreichDurchgefuehrt = false;
    this.bewegungsbuchungErgebnis.spFehlerStatus = null;
    this.bewegungsbuchungErgebnis.spFehlermeldung = null;
  }

  private oeffneMeldungenDialog() {
    // console.log ("awteinlagern-formular.component oeffneMeldungenDialog" + this.meldungen);
    this.modalService.open(this.meldungen, { scrollable: true, centered: true });

    this.focusRad.nativeElement.focus(); // funktioniert hier nicht
  }


  private zusammenfasserImZugriffUndPaletteDetails(imZugriff: boolean, produktDetails: PaletteInfoErgebnis) {
    return {
      imZugriff: imZugriff,
      produktDetails: produktDetails
    };
  }


  private aktiviereFormular(imZugriff: boolean) {
    this.bewegungsbuchungImZugriff = imZugriff;
    if (imZugriff) {
      // this.formular.disable({ emitEvent: false });
      // RD 20200722 wir sperren das Formular einfach nie, damit Focus weiterhin funktioniert
      this.formular.enable({ emitEvent: false });
    } else {
      this.formular.enable({ emitEvent: false });
    }
  }

  ngOnDestroy() {
    this.observableBeobachter.forEach(beobachter => beobachter.unsubscribe());
    this.store.dispatch(ladeInitialZustandBarcodeScan());
    this.ladeInitialZustandBewegungsbuchung();
  }

  scanStarten() {
    this.leereBewegungsbuchungErgebnis();
    this.scanFormularComponent.scanStarten();
  }

  barcodeGescannt(code: string) {
    this.barcodeVerarbeitungService.verarbeiteBarcode(code);
    this.ladeInitialZustandBewegungsbuchung();
  }

  buchen(formulardaten) {
    this.ladeInitialZustandBewegungsbuchung();

    // console.log("starte buchen" + formulardaten);

    if (this.formular.invalid) {
      // console.log("buchen invalid!");

      this.palette.markAsDirty();
      this.rad.markAsDirty();

      this.oeffneMeldungenDialog();
      return;
    }


    let buchungsdatum = new Date(); // Typescript Datum ist UTC, wir müssen in GMT+2 umrechnen:
    buchungsdatum = new Date( buchungsdatum.getTime() + (buchungsdatum.getTimezoneOffset() * 1000) );
    // console.log("buchen Zeit " + buchungsdatum.toString() );

    let anfrage: AuslagernAnfrage = {
      token: this.autorisierungService.benutzerAutorisierung.token,
      aufNr: this.rad.value,
      BewaID: -4, /* Bewegung Auslagern */
      Rollwagen: this.palette.value,
      Kennzeichen: '',
      Benutzer: this.autorisierungService.benutzerAutorisierung.anzeigename,
      RadAnzahl: 4,
      Daten: '',
      paramHaendler_ID: 0,
      bewd_Zeit: buchungsdatum
    };

    // console.log("buchen BewegungsbuchungAnfrage erzeugt");

    this.store.dispatch(starteBewegungsbuchung(anfrage));
  }

  private verarbeiteBewegungsbuchungFeature(ergebnis: AuslagernZustand) {
    // console.log("awteinlagern-formular.component.ts verarbeiteBewegungsbuchungFeature imZugriff: " + ergebnis.imZugriff + " erfolgreichDurchgefuehrt: " + ergebnis.bewegungsbuchungErgebnis.erfolgreichDurchgefuehrt);

    if (!ergebnis.imZugriff) {
      if (ergebnis.auslagernErgebnis.erfolgreichDurchgefuehrt) {

        this.palette.setValue('');
        this.rad.setValue('');
        this.palInfo.setValue('');

        this.formular.markAsUntouched();
        this.formular.markAsPristine();

        // "Die Bewegung wurde erfolgreich gebucht."

//        this.focusRack.nativeElement.focus(); // funktioniert hier nicht

        this.oeffneMeldungenDialog();

//        this.focusRack.nativeElement.focus(); // funktioniert hier nicht

      } else if (ergebnis.auslagernErgebnis.spFehlerStatus != null) {
        this.oeffneMeldungenDialog();
      }
      this.bewegungsbuchungErgebnisDaten = ergebnis.auslagernErgebnis;
    }
  }

  private ladeInitialZustandBewegungsbuchung() {
    this.store.dispatch(ladeInitialZustandBewegungsbuchung());
  }


  private verarbeitePaletteDetails(ergebnis: AuslagernZustand ) {   // ergebnis: AuslagernZustand  PaletteinfoZustand  )} //
    console.log ("awtauslagern-formular.components verarbeitePaletteDetails");
//debugger
    // this.lagerortBarcodeScanImZugriff = zusammenfassung.imZugriff;
    //if (!zusammenfassung.imZugriff && (zusammenfassung.paletteDetails.spFehlerStatus)) {
      //this.paletteinfo = zusammenfassung.paletteDetails;
    //  this.palInfo.markAsDirty();
      /* if (this.lagerortDetails.lagerortId && !this.lagerortDetails.spFehlerStatus) {
        this.paletteinfo.setValue(`${this.lagerortDetails.bezeichnung} R${this.lagerortDetails.reihe} S${this.lagerortDetails.staender} F${this.lagerortDetails.fach}`);
      } else {
        this.paletteinfo.setValue('?');
        this.oeffneMeldungenDialog();
      } */
      //if (!zusammenfassung.imZugriff && (zusammenfassung.paletteDetails.spFehlerStatus)) {
 // debugger
      if (ergebnis) {
        if (ergebnis.paletteinfoErgebnis.auftrag) {
          this.palInfo.setValue("- " + ergebnis.paletteinfoErgebnis.klbezeichnung + ' ' + ergebnis.paletteinfoErgebnis.auftrag + " " + ergebnis.paletteinfoErgebnis.auslagerziel +
          "\n- " + ergebnis.paletteinfoErgebnis.klbezeichnung2 + ' ' + ergebnis.paletteinfoErgebnis.auftrag2 + " " + ergebnis.paletteinfoErgebnis.auslagerziel2);

          // fuer naechsten scan Focus auf Rad Feld stellen
          this.focusRad.nativeElement.focus();
        }
      }

      //}
    //}
  }


  geheZurueckZurNavigation() {
    this.router.navigate(['/navigation'], { relativeTo: this.route.root});
  }
}
