import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppZustand } from '../shared/interfaces/app-zustand';
import { VerbrauchsbuchungZustand } from './interfaces/verbrauchsbuchung-zustand';
import { verbrauchsbuchungFeatureName } from './verbrauchsbuchung.reducer';

export const waehleVerbrauchsbuchungFeature = createFeatureSelector<AppZustand, VerbrauchsbuchungZustand>(verbrauchsbuchungFeatureName);

export const waehleVerbrauchsbuchungErgebnis = createSelector(
  waehleVerbrauchsbuchungFeature,
  zustand => zustand.verbrauchsbuchungErgebnis
);

export const waehleImZugriff = createSelector(
  waehleVerbrauchsbuchungFeature,
  zustand => zustand.imZugriff
);
