import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutorisierungGuard } from '../backend-zugriff/autorisierung/autorisierung.guard';
import { AwtweFormularComponent } from './awtwe-formular/awtwe-formular.component';

const routes: Routes = [
  { path: 'awtwe', component: AwtweFormularComponent, canActivate: [AutorisierungGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AwtweRoutingModule { }
