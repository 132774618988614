import { Component, Input } from '@angular/core';

@Component({
  selector: 'lagermanager-error-symbol',
  templateUrl: './error-symbol.component.html',
  styleUrls: ['./error-symbol.component.scss']
})
export class ErrorSymbolComponent {
  @Input('class') klassen = '';
}
