import { Component, Input } from '@angular/core';

@Component({
  selector: 'lagermanager-cancel-symbol',
  templateUrl: './cancel-symbol.component.html',
  styleUrls: ['./cancel-symbol.component.scss']
})
export class CancelSymbolComponent {
  @Input('class') klassen = '';
}
