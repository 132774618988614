import { createReducer, on } from '@ngrx/store';
import { Konfiguration } from '../shared/interfaces/konfiguration';
import { KonfigurationZustand } from '../shared/interfaces/konfiguration-zustand';
import { konfigurationGeladen, ladeInitialZustand, ladeKonfiguration } from './konfiguration.actions';
export const konfigurationFeatureName = 'konfiguration';

export const initialZustand: KonfigurationZustand = {
  konfiguration: {
    verbrauchsbuchung: {
      lieferantSichtbar: false,
      lieferantMerken: false,
      lagerortMerken: false
    }
  },
  imZugriff: false
};

function onLadeKonfiguration(zustand: KonfigurationZustand): KonfigurationZustand {
  return {
    ...initialZustand,
    imZugriff: true
  };
}

function onKonfigurationGeladen(zustand: KonfigurationZustand, actionDaten: Konfiguration): KonfigurationZustand {
  return {
    ...zustand,
    konfiguration: {
      ...actionDaten
    },
    imZugriff: false
  };
}

function onLadeInitialZustand(zustand: KonfigurationZustand): KonfigurationZustand {
  return initialZustand;
}

const reducer = createReducer(initialZustand,
  on(ladeKonfiguration, onLadeKonfiguration),
  on(konfigurationGeladen, onKonfigurationGeladen),
  on(ladeInitialZustand, onLadeInitialZustand)
);

export function konfigurationReducer(zustand, action) {
  return reducer(zustand, action);
}
