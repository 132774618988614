import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor (private translate: TranslateService) {
    this.translate.setDefaultLang('de');
    this.translate.use('de');
    //this.translate.addLangs(['de']);
    this.translate.addLangs(['en']);
    this.translate.addLangs(['fr']);
  }
}
