import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppZustand } from '../shared/interfaces/app-zustand';
import { AuslagernZustand } from './interfaces/auslagern-zustand'; // PaletteinfoZustand
import { auslagenFeatureName } from './awtauslagern.reducer'; // paletteinfoFeatureName
// import { PaletteinfoZustand } from './interfaces/paletteinfo-zustand';

export const waehleAuslagernFeature = createFeatureSelector<AppZustand, AuslagernZustand>(auslagenFeatureName);

// export const waehlePaletteinfoFeature = createFeatureSelector<AppZustand, PaletteinfoZustand>(paletteinfoFeatureName);
export const waehlePaletteinfoFeature = createFeatureSelector<AppZustand, AuslagernZustand>(auslagenFeatureName);

export const waehleBewegungsbuchungErgebnis = createSelector(
  waehleAuslagernFeature,
  zustand => zustand.auslagernErgebnis
);

export const waehleImZugriff = createSelector(
  waehleAuslagernFeature,
  zustand => zustand.imZugriff
);

/*
export const waehlePaletteinfoErgebnis = createSelector(
  waehlePaletteinfoFeature,
  zustand => zustand.paletteinfoErgebnis
);
*/

/*
export const waehleLagerortImZugriff = createSelector(
  waehleBarcodeScanFeature,
  zustand => zustand.lagerortImZugriff
);

export const waehleLagerortDetails = createSelector(
  waehleBarcodeScanFeature,
  zustand => zustand.lagerortDetails
);

*/
