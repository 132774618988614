import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppZustand } from '../shared/interfaces/app-zustand';
import { WabuchungZustand } from './interfaces/wabuchung-zustand';
import { wabuchungFeatureName } from './awtwa.reducer';

export const waehleBewegungsbuchungFeature = createFeatureSelector<AppZustand, WabuchungZustand>(wabuchungFeatureName);

export const waehleBewegungsbuchungErgebnis = createSelector(
  waehleBewegungsbuchungFeature,
  zustand => zustand.wabuchungErgebnis
);

export const waehleImZugriff = createSelector(
  waehleBewegungsbuchungFeature,
  zustand => zustand.imZugriff
);
