import { Component, Input } from '@angular/core';

@Component({
  selector: 'lagermanager-barcode-symbol',
  templateUrl: './barcode-symbol.component.html',
  styleUrls: ['./barcode-symbol.component.scss']
})
export class BarcodeSymbolComponent {
  @Input('class') klassen = '';
}
