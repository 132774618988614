import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DotRoutingModule } from './dot-routing.module';
import { DotComponent } from './dot.component';
import { FormsModule } from '@angular/forms';  // Importiere FormsModule hier


@NgModule({
  declarations: [DotComponent],
  imports: [
    CommonModule,
    DotRoutingModule,
    FormsModule
  ]
})
export class DotModule { }
