import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutorisierungGuard } from '../backend-zugriff/autorisierung/autorisierung.guard';
import { AwteinlagernFormularComponent } from './awteinlagern-formular/awteinlagern-formular.component';

const routes: Routes = [
  { path: 'awteinlagern', component: AwteinlagernFormularComponent, canActivate: [AutorisierungGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AwteinlagernRoutingModule { }
