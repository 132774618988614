import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BackendZugriffModule } from '../backend-zugriff/backend-zugriff.module';
// import { BarcodeScanModule } from '../barcode-scan/barcode-scan.module';
import { SharedModule } from '../shared/shared.module';
import { AwteinlagernFormularComponent } from './awteinlagern-formular/awteinlagern-formular.component';
import { AwteinlagernRoutingModule } from './awteinlagern-routing.module';
import { AwteinlagernEffects } from './awteinlagern.effects';
import { bewegungsbuchungFeatureName, bewegungsbuchungReducer } from './awteinlagern.reducer';

@NgModule({
  declarations: [AwteinlagernFormularComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule,
    BackendZugriffModule,
    // BarcodeScanModule,
    ReactiveFormsModule,
    StoreModule.forFeature(bewegungsbuchungFeatureName, bewegungsbuchungReducer),
    EffectsModule.forFeature([AwteinlagernEffects]),
    AwteinlagernRoutingModule
  ]
})
export class AwteinlagernModule { }
