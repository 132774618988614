import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutorisierungGuard } from '../backend-zugriff/autorisierung/autorisierung.guard';
import { VerbrauchsbuchungFormularComponent } from './verbrauchsbuchung-formular/verbrauchsbuchung-formular.component';

const routes: Routes = [
  { path: 'verbrauchsbuchung', component: VerbrauchsbuchungFormularComponent, canActivate: [AutorisierungGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VerbrauchsbuchungRoutingModule { }
