import { Component, Input } from '@angular/core';

@Component({
  selector: 'lagermanager-verbrauchsbuchung-symbol',
  templateUrl: './verbrauchsbuchung-symbol.component.html',
  styleUrls: ['./verbrauchsbuchung-symbol.component.scss']
})
export class VerbrauchsbuchungSymbolComponent {
  @Input('class') klassen = '';
}
