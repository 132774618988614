import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppZustand } from '../shared/interfaces/app-zustand';
import { BewegungsbuchungZustand } from './interfaces/bewegungsbuchung-zustand';
import { bewegungsbuchungFeatureName } from './awteinlagern.reducer';

export const waehleBewegungsbuchungFeature = createFeatureSelector<AppZustand, BewegungsbuchungZustand>(bewegungsbuchungFeatureName);

export const waehleBewegungsbuchungErgebnis = createSelector(
  waehleBewegungsbuchungFeature,
  zustand => zustand.bewegungsbuchungErgebnis
);

export const waehleImZugriff = createSelector(
  waehleBewegungsbuchungFeature,
  zustand => zustand.imZugriff
);
