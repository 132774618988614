import { Component, Input } from '@angular/core';

@Component({
  selector: 'lagermanager-auftragsbuchungen-symbol',
  templateUrl: './auftragsbuchungen-symbol.component.html',
  styleUrls: ['./auftragsbuchungen-symbol.component.scss']
})
export class AuftragsbuchungenSymbolComponent {
  @Input('class') klassen = '';
}
