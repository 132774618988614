import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { AutorisierungService } from '../backend-zugriff/autorisierung/autorisierung.service';
import { UrlService } from '../shared/url-service/url.service';

import { AuslagernAnfrage } from './interfaces/auslagern-anfrage';
import { AuslagernErgebnis } from './interfaces/auslagern-ergebnis';

import { PaletteInfoAnfrage } from './interfaces/paletteinfo-anfrage';
import { PaletteInfoErgebnis } from './interfaces/paletteinfo-ergebnis';

import { starteBewegungsbuchung, bewegungsBuchungAbgeschlossen, startePaletteInfoAnfrage, paletteinfoGeladen } from './awtauslagern.actions';

@Injectable()
export class AwtauslagernEffects {
  starteBewegungsbuchung$ = createEffect(() => this.action$.pipe(
    ofType(starteBewegungsbuchung.type),
    mergeMap((actionDaten: AuslagernAnfrage) => {
      return this.http.post<AuslagernErgebnis>(this.urlService.erstelleUrl(this.autorisierungService.firmenbackendUrl.firmenbackendUrl,
          'api/bewegungsbuchung'), actionDaten).pipe(
        map(ergebnisse => bewegungsBuchungAbgeschlossen(ergebnisse))
      );

      //console.log("awteinlagern.effects AwteinlagernEffects starteBewegungsbuchung");

    })
  ));


  startePaletteInfoAnfrage$ = createEffect(() => this.action$.pipe(
    ofType(startePaletteInfoAnfrage.type),
    mergeMap((actionDaten: PaletteInfoAnfrage) => {
//debugger;
      var enkodiertesToken = encodeURIComponent(this.autorisierungService.benutzerAutorisierung.token);
      var enkodierterBarcode = encodeURIComponent(actionDaten.barcode);

      console.log("awtauslagern.effects PaletteInfoAnfrage " + this.autorisierungService.firmenbackendUrl.firmenbackendUrl +
      `api/paletteinfo/${enkodiertesToken}/${enkodierterBarcode}` );

      return this.http.get<PaletteInfoErgebnis>(this.urlService.erstelleUrl(this.autorisierungService.firmenbackendUrl.firmenbackendUrl,
        `api/paletteinfo/${enkodiertesToken}/${enkodierterBarcode}`)).pipe(
        map(ergebnisse => paletteinfoGeladen(ergebnisse))
      );
// api/paletteinfo .. lagerort
//Stattdessen muss es return this.http.get heißen und der Parameter actionDaten muss entfernt werden - das ist bei http.post() der Parameter für den Request-Body, den es bei http.get() nicht gibt.

    })
  ));

/*
  ladeLagerortdetailsZuBarcode$ = createEffect(() => this.action$.pipe(
    ofType(ladeLagerortdetailsZuBarcode.type),
    mergeMap((actionDaten: LagerortBarcodeDaten) => {
      var enkodiertesToken = encodeURIComponent(this.autorisierungService.benutzerAutorisierung.token);
      var enkodierterBarcode = encodeURIComponent(actionDaten.barcode);
      return this.httpClient.get<LagerortDetails>(this.urlService.erstelleUrl(this.autorisierungService.firmenbackendUrl.firmenbackendUrl,
          `api/lagerort/${enkodiertesToken}/${enkodierterBarcode}`)).pipe(
        map(ergebnisse => lagerortdetailsZuBarcodeGeladen(ergebnisse))
      );
    })
  ));
  */

/*
  ladeLagerortdetailsZuBarcode$ = createEffect(() => this.action$.pipe(
    ofType(ladeLagerortdetailsZuBarcode.type),
    mergeMap((actionDaten: LagerortBarcodeDaten) => {
      var enkodiertesToken = encodeURIComponent(this.autorisierungService.benutzerAutorisierung.token);
      var enkodierterBarcode = encodeURIComponent(actionDaten.barcode);
      return this.httpClient.get<LagerortDetails>(this.urlService.erstelleUrl(this.autorisierungService.firmenbackendUrl.firmenbackendUrl, `api/lagerort/${enkodiertesToken}/${enkodierterBarcode}`)).pipe(
        map(ergebnisse => lagerortdetailsZuBarcodeGeladen(ergebnisse))
      );
    })
  ));
*/

  constructor(private action$: Actions, private http: HttpClient, private urlService: UrlService, private autorisierungService: AutorisierungService) {
    //console.log("awteinlagern.effects constructor");
  }
}
