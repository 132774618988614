import { Component, Input } from '@angular/core';

@Component({
  selector: 'lagermanager-warenausgang-symbol',
  templateUrl: './warenausgang-symbol.component.html',
  styleUrls: ['./warenausgang-symbol.component.scss']
})
export class WarenausgangSymbolComponent {
  @Input('class') klassen = '';
}
