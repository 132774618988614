import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AuslagernComponent } from './auslagern/auslagern.component';
import { HauptmenueComponent } from './hauptmenue/hauptmenue.component';
import { NavigationRoutingModule } from './navigation-routing.module';

@NgModule({
  declarations: [HauptmenueComponent, AuslagernComponent],
  imports: [
    CommonModule,
    SharedModule,
    NavigationRoutingModule
  ]
})
export class NavigationModule { }
