import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BackendZugriffModule } from '../backend-zugriff.module';
import { AutorisierungService } from './autorisierung.service';

@Injectable({
  providedIn: BackendZugriffModule
})
export class AutorisierungGuard implements CanActivate, CanActivateChild {
  constructor(private autorisierungService: AutorisierungService) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (!!this.autorisierungService.benutzerAutorisierung.token) {
        return true;
      }
      this.autorisierungService.loggeAus();
    return false;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.canActivate(next, state);
  }

}
