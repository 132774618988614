import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppZustand } from '../shared/interfaces/app-zustand';
import { barcodeScanFeatureName } from './barcode-scan.reducer';
import { BarcodeScanZustand } from './interfaces/barcode-scan-zustand';

export const waehleBarcodeScanFeature = createFeatureSelector<AppZustand, BarcodeScanZustand>(barcodeScanFeatureName);

export const waehleLagerortImZugriff = createSelector(
  waehleBarcodeScanFeature,
  zustand => zustand.lagerortImZugriff
);

export const waehleLagerortDetails = createSelector(
  waehleBarcodeScanFeature,
  zustand => zustand.lagerortDetails
);

export const waehleLieferantImZugriff = createSelector(
  waehleBarcodeScanFeature,
  zustand => zustand.lieferantImZugriff
);

export const waehleLieferantDetails = createSelector(
  waehleBarcodeScanFeature,
  zustand => zustand.lieferantDetails
);

export const waehleProduktImZugriff = createSelector(
  waehleBarcodeScanFeature,
  zustand => zustand.produktImZugriff
);

export const waehleProduktDetails = createSelector(
  waehleBarcodeScanFeature,
  zustand => zustand.produktDetails
);
