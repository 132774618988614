import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { starteImage, imageAbgeschlossen } from './image.actions';
import { mergeMap, map } from 'rxjs/operators';
import { ImageAnfrage } from './interfaces/image-anfrage';
import { HttpClient } from '@angular/common/http';
import { UrlService } from '../shared/url-service/url.service';
import { AutorisierungService } from '../backend-zugriff/autorisierung/autorisierung.service';
import { ImageErgebnis } from './interfaces/image-ergebnis';

@Injectable()
export class ImageEffects {
  starteImage$ = createEffect(() => this.action$.pipe(
    ofType(starteImage.type),
    mergeMap((actionDaten: ImageAnfrage) => {
      return this.http.post<ImageErgebnis>(this.urlService.erstelleUrl(this.autorisierungService.firmenbackendUrl.firmenbackendUrl, 'api/image'), actionDaten).pipe(
        map(ergebnisse => imageAbgeschlossen(ergebnisse))
      );
    })
  ));

  constructor(private action$: Actions, private http: HttpClient, private urlService: UrlService, private autorisierungService: AutorisierungService) {}
}
