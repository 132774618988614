import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { AutorisierungService } from '../backend-zugriff/autorisierung/autorisierung.service';
import { UrlService } from '../shared/url-service/url.service';
import { BewegungsbuchungAnfrage } from './interfaces/webuchung-anfrage';
import { WebuchungErgebnis } from './interfaces/webuchung-ergebnis';
import { starteBewegungsbuchung, bewegungsBuchungAbgeschlossen } from './awtwe.actions';

@Injectable()
export class AwtweEffects {
  starteBewegungsbuchung$ = createEffect(() => this.action$.pipe(
    ofType(starteBewegungsbuchung.type),
    mergeMap((actionDaten: BewegungsbuchungAnfrage) => {
      return this.http.post<WebuchungErgebnis>(this.urlService.erstelleUrl(this.autorisierungService.firmenbackendUrl.firmenbackendUrl,
          'api/bewegungsbuchung'), actionDaten).pipe(
        map(ergebnisse => bewegungsBuchungAbgeschlossen(ergebnisse))
      );

      //console.log("awteinlagern.effects AwteinlagernEffects starteBewegungsbuchung");

    })
  ));

  constructor(private action$: Actions, private http: HttpClient, private urlService: UrlService, private autorisierungService: AutorisierungService) {
    //console.log("awteinlagern.effects constructor");
  }
}
