import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FelgenartRoutingModule } from './felgenart-routing.module';
import { FelgenartComponent } from './felgenart.component';
import { FormsModule } from '@angular/forms';  // Importiere FormsModule hier


@NgModule({
  declarations: [FelgenartComponent],
  imports: [
    CommonModule,
    FelgenartRoutingModule,
    FormsModule
  ]
})
export class FelgenartModule { }
