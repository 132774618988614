import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { ImageZustand } from '../interfaces/image-zustand';
import { starteImage } from '../image.actions';
import { ImageAnfrage } from '../interfaces/image-anfrage';

@Component({
  selector: 'app-image-formular',
  templateUrl: './image-formular.component.html',
  styleUrls: ['./image-formular.component.scss']
})
export class ImageFormularComponent implements OnInit {
  // Ein ElementRef-Objekt, um das hochgeladene Bild gleich anzeigen zu können.
  @ViewChild('ziel', { static: false}) ziel: ElementRef;

  constructor(private store: Store<ImageZustand>) { }

  ngOnInit() {
  }

  // Der Eventhandler, der bei Auswahl eines Bilds feuert.
  imageGeaendert(event) {
    console.log('imageGeaendert', event);

    // event.target.files ist die Liste der ausgewählten Dateien.
    let fileList = event.target.files;

    if (fileList && fileList.length && fileList[0].type.match(/^image\//)) {
      // Mit dem FileReader werden die Daten des ausgewählten Bilds ausgelesen.
      // Momentan werden die Daten als Data-URL gelesen.
      // FileReader benutzt dabei selbst das Event onload, mit dem es anzeigt,
      // dass die Datei komplett geladen wurde.
      let fileReader = new FileReader();

      fileReader.onload = (fileReaderEvent) => {
        console.log('fileReaderEvent', fileReaderEvent);
        //let originalData = fileReaderEvent.target.result;

        //var fileUrl = imgsrc.target.result; //change to
        var originalData = (fileReaderEvent.target as FileReader).result; //cast to correct type -- https://dev.to/hasanin19salah/answer-new-typescript-1-8-4-build-error-build-property-result-does-not-exist-on-type-eventtarget-1ebn

        // Die Prüfung auf string wird vorgenommen, damit TypeScript keinen Typfehler bei .split() meldet.
        if (typeof originalData === 'string') {
          this.ziel.nativeElement.src = originalData;

          // Hier wird die Data-URL zerteilt, um den Base64-Anteil zu extrahieren.
          // Das muss aber nicht clientseitig gemacht werden. Eventuell kann die
          // gesamte Data-URL in der Datenbank gespeichert werden, wenn keine
          // serverseitige Verarbeitung des Bilds notwendig ist.
          let data = originalData.split(',')[1];

          // Die Base64-Daten werden an den Server geschickt.
          let anfrage: ImageAnfrage = {
            imageBase64: data,
            auftrag: "1000000001"
          };

          this.store.dispatch(starteImage(anfrage))
        }
      }

      fileReader.readAsDataURL(fileList[0]);
    }
  }
}
