import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { BarcodeScanKonfiguration } from '../interfaces/barcode-scan-konfiguration';
import { BarcodeScanner } from '../interfaces/barcode-scanner';
import { ZxingScanner } from '../zxing-scanner';

@Component({
  selector: 'lagermanager-scan-formular',
  templateUrl: './scan-formular.component.html',
  styleUrls: ['./scan-formular.component.scss']
})
export class ScanFormularComponent implements OnInit {
  @Input() erwarteteInhaltsformate = [];
  @Output() barcodeGescannt = new EventEmitter<string>();

  istScanAktiv = false;
  private scanner: BarcodeScanner;
  private ergebnisLeser: Subscription;

  constructor() { }

  ngOnInit() {
    this.scanner = new ZxingScanner();

    this.scanner.initialisiereScanner({
      videoElementId: 'video',
      videoElementContainerId: 'videoContainer',
      erwarteteInhaltsformate: this.erwarteteInhaltsformate
    } as BarcodeScanKonfiguration)
  }

  public scanStarten() {
    this.istScanAktiv = true;
    this.ergebnisLeser = this.scanner.starteScan().subscribe(code => {
      this.barcodeGescannt.emit(code);
      this.scanAbbrechen();
    });
  }

  scanAbbrechen() {
    this.istScanAktiv = false;
    this.ergebnisLeser.unsubscribe();
    this.scanner.stoppeScan();
  }
}
