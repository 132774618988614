import { Component, Input } from '@angular/core';

@Component({
  selector: 'lagermanager-kamera-symbol',
  templateUrl: './kamera-symbol.component.html',
  styleUrls: ['./kamera-symbol.component.scss']
})
export class KameraSymbolComponent {
  @Input('class') klassen = '';
}
