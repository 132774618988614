import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AuftragsbuchungenSymbolComponent } from './symbole/auftragsbuchungen-symbol/auftragsbuchungen-symbol.component';
import { AuslagernSymbolComponent } from './symbole/auslagern-symbol/auslagern-symbol.component';
import { BarcodeSymbolComponent } from './symbole/barcode-symbol/barcode-symbol.component';
import { CancelSymbolComponent } from './symbole/cancel-symbol/cancel-symbol.component';
import { CheckSymbolComponent } from './symbole/check-symbol/check-symbol.component';
import { EinlagernSymbolComponent } from './symbole/einlagern-symbol/einlagern-symbol.component';
import { ErfolgSymbolComponent } from './symbole/erfolg-symbol/erfolg-symbol.component';
import { ErrorSymbolComponent } from './symbole/error-symbol/error-symbol.component';
import { FirmenkuerzelSymbolComponent } from './symbole/firmenkuerzel-symbol/firmenkuerzel-symbol.component';
import { InfoSymbolComponent } from './symbole/info-symbol/info-symbol.component';
import { KommissionierlistenSymbolComponent } from './symbole/kommissionierlisten-symbol/kommissionierlisten-symbol.component';
import { LoginSymbolComponent } from './symbole/login-symbol/login-symbol.component';
import { LogoutSymbolComponent } from './symbole/logout-symbol/logout-symbol.component';
import { PasswordSymbolComponent } from './symbole/password-symbol/password-symbol.component';
import { UmlagernSymbolComponent } from './symbole/umlagern-symbol/umlagern-symbol.component';
import { UserSymbolComponent } from './symbole/user-symbol/user-symbol.component';
import { VerbrauchsbuchungSymbolComponent } from './symbole/verbrauchsbuchung-symbol/verbrauchsbuchung-symbol.component';
import { VerladungSymbolComponent } from './symbole/verladung-symbol/verladung-symbol.component';
import { WarenausgangSymbolComponent } from './symbole/warenausgang-symbol/warenausgang-symbol.component';
import { ZurueckSymbolComponent } from './symbole/zurueck-symbol/zurueck-symbol.component';
import { KameraSymbolComponent } from './symbole/kamera-symbol/kamera-symbol.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    TranslateModule,
    BarcodeSymbolComponent,
    CancelSymbolComponent,
    CheckSymbolComponent,
    ErrorSymbolComponent,
    LogoutSymbolComponent,
    ZurueckSymbolComponent,
    AuftragsbuchungenSymbolComponent,
    KommissionierlistenSymbolComponent,
    VerbrauchsbuchungSymbolComponent,
    VerladungSymbolComponent,
    WarenausgangSymbolComponent,
    FirmenkuerzelSymbolComponent,
    LoginSymbolComponent,
    PasswordSymbolComponent,
    UserSymbolComponent,
    AuslagernSymbolComponent,
    EinlagernSymbolComponent,
    InfoSymbolComponent,
    UmlagernSymbolComponent,
    ErfolgSymbolComponent,
    KameraSymbolComponent
  ],
  declarations: [
    BarcodeSymbolComponent,
    CancelSymbolComponent,
    CheckSymbolComponent,
    ErrorSymbolComponent,
    LogoutSymbolComponent,
    ZurueckSymbolComponent,
    AuftragsbuchungenSymbolComponent,
    KommissionierlistenSymbolComponent,
    VerbrauchsbuchungSymbolComponent,
    VerladungSymbolComponent,
    WarenausgangSymbolComponent,
    FirmenkuerzelSymbolComponent,
    LoginSymbolComponent,
    PasswordSymbolComponent,
    UserSymbolComponent,
    AuslagernSymbolComponent,
    EinlagernSymbolComponent,
    InfoSymbolComponent,
    UmlagernSymbolComponent,
    ErfolgSymbolComponent,
    KameraSymbolComponent
  ]
})
export class SharedModule { }
