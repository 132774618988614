import { Component, Input } from '@angular/core';

@Component({
  selector: 'lagermanager-login-symbol',
  templateUrl: './login-symbol.component.html',
  styleUrls: ['./login-symbol.component.scss']
})
export class LoginSymbolComponent {
  @Input('class') klassen = '';
}
