import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { radsatzBarcodeInhaltsformat } from 'src/app/shared/konstanten';
import { AutorisierungService } from '../../backend-zugriff/autorisierung/autorisierung.service';
import { ladeInitialZustand as ladeInitialZustandBarcodeScan } from '../../barcode-scan/barcode-scan.actions';
import { waehleLagerortDetails, waehleLagerortImZugriff, waehleLieferantDetails, waehleLieferantImZugriff, waehleProduktDetails, waehleProduktImZugriff } from '../../barcode-scan/barcode-scan.selectors';
import { BarcodeVerarbeitungService } from '../../barcode-scan/barcode-verarbeitung.service';
import { LagerortDetails } from '../../barcode-scan/interfaces/lagerort-details';
import { LieferantDetails } from '../../barcode-scan/interfaces/lieferant-details';
import { ProduktDetails } from '../../barcode-scan/interfaces/produkt-details';
import { ScanFormularComponent } from '../../barcode-scan/scan-formular/scan-formular.component';
import { KonfigurationService } from '../../konfiguration/konfiguration.service';
import { AppZustand } from '../../shared/interfaces/app-zustand';
import { datenBekanntValidierer } from '../../shared/validierer/daten-bekannt-validierer';
import { zahlValidierer } from '../../shared/validierer/zahl-validierer';
import { RadscanAnfrage } from './interfaces/radscan-anfrage';
import { RadscanErgebnis } from './interfaces/radscan-ergebnis';
//import { selectRadscanErgebnis } from './radscan.selectors';
import { waehleRadscanErgebnis } from './radscan.selectors';

//import { RadscanZustand } from '../../verbrauchsbuchung/interfaces/verbrauchsbuchung-zustand';
// import { ladeInitialZustand as ladeInitialZustandVerbrauchsbuchung, starteVerbrauchsbuchung } from '../../verbrauchsbuchung/verbrauchsbuchung.actions';
import { ladeInitialZustandRadscan as ladeInitialZustandRadscan, starteRadscan } from './radscan.actions';

import { waehleImZugriff as waehleVerbrauchsbuchungImZugriff, waehleVerbrauchsbuchungFeature } from '../../verbrauchsbuchung/verbrauchsbuchung.selectors';
import { Router, ActivatedRoute } from '@angular/router';
import { RadsatzDetails } from '../../barcode-scan/interfaces/radsatz-details';
import { Observable } from 'rxjs';

@Component({
  selector: 'lagermanager-radscan',
  templateUrl: './radscan-formular.component.html',
  styleUrls: ['./radscan-formular.component.scss']
})
export class RadscanFormularComponent implements OnInit, OnDestroy {
  private observableBeobachter: Subscription[] = [];

  @ViewChild(ScanFormularComponent, { static: false })
  private scanFormularComponent: ScanFormularComponent;

  @ViewChild('meldungen', { static: false })
  private meldungen;

  radscanErgebnis$: Observable<RadscanErgebnis>;

  erwarteteBarcodeInhaltsformate = [
    radsatzBarcodeInhaltsformat
  ];

  get istScanAktiv() {
    return this.scanFormularComponent && this.scanFormularComponent.istScanAktiv;
  }

  radscanImZugriff: boolean;
  private radscanErgebnisDaten: RadscanErgebnis = {
    Radsatz: "",
    DatenVL: "",
    DatenVR: "",
    DatenHL: "",
    DatenHR: "",
    spFehlerStatus: null,
    spFehlermeldung: null
  };

  get barcodeScanImZugriff() {
    return this.lagerortBarcodeScanImZugriff || this.lieferantBarcodeScanImZugriff || this.produktBarcodeScanImZugriff;
  }

  private lagerortBarcodeScanImZugriff = false;
  private lieferantBarcodeScanImZugriff = false;
  private produktBarcodeScanImZugriff = false;


  get radscanErgebnis() {
    return this.radscanErgebnisDaten;
  }



  formular: FormGroup;

  private lagerortDetails: LagerortDetails;
  private lieferantDetails: LieferantDetails;
  private produktDetails: ProduktDetails;

  get radsatz() {
    return this.formular.get('radsatz');
  }



  constructor(private barcodeVerarbeitungService: BarcodeVerarbeitungService, private formBuilder: FormBuilder, private store: Store<AppZustand>,
    private konfigurationService: KonfigurationService, private autorisierungService: AutorisierungService, private modalService: NgbModal,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.formular = this.formBuilder.group({
      radsatz: new FormControl('', {
        validators: [Validators.required, datenBekanntValidierer(() => this.lieferantDetailsBekannt())]
      })
    });

    let lagerortImZugriff$ = this.store.select(waehleLagerortImZugriff);
    let lagerortDetails$ = this.store.select(waehleLagerortDetails);
    let lieferantImZugriff$ = this.store.select(waehleLieferantImZugriff);
    let lieferantDetails$ = this.store.select(waehleLieferantDetails);
    let produktImZugriff$ = this.store.select(waehleProduktImZugriff);
    let produktDetails$ = this.store.select(waehleProduktDetails);

    this.radscanErgebnis$ = this.store.select(waehleRadscanErgebnis);

    //this.radscanErgebnis$.subscribe(radscanErgebnisDaten)
/*
    this.radscanErgebnis$ = this.store.pipe(select(selectRadscanErgebnis));

    this.radscanErgebnis$.subscribe((ergebnis) => {
      if (ergebnis && ergebnis.Radsatz) {
        this.buttonLabel = 'Radscan erneut starten';
      } else {
        this.buttonLabel = 'Starte Radscan';
      }
    });
    */
  }


  private zusamenfasserImZugriffUndLagerortDetails(imZugriff: boolean, lagerortDetails: LagerortDetails) {
    return {
      imZugriff: imZugriff,
      lagerortDetails: lagerortDetails
    };
  }
  private zusammenfasserImZugriffUndLieferantDetails(imZugriff: boolean, lieferantDetails: LieferantDetails) {
    return {
      imZugriff: imZugriff,
      lieferantDetails: lieferantDetails
    };
  }



  private zusammenfasserImZugriffUndProduktDetails(imZugriff: boolean, produktDetails: ProduktDetails) {
    return {
      imZugriff: imZugriff,
      produktDetails: produktDetails
    };
  }


  private aktiviereFormular(imZugriff: boolean) {
    //this.verbrauchsbuchungImZugriff = imZugriff;
    if (imZugriff) {
      this.formular.disable({ emitEvent: false });
    } else {
      this.formular.enable({ emitEvent: false });
    }
  }

  lagerortDetailsBekannt(): boolean {
    return !this.lagerortDetails ||
      !this.lagerortDetails.spFehlerStatus &&
      !this.lagerortDetails.spFehlermeldung;
  }

  lieferantDetailsBekannt(): boolean {
    return !this.lieferantDetails ||
      !this.lieferantDetails.spFehlerStatus &&
      !this.lieferantDetails.spFehlermeldung;
  }

  produktDetailsBekannt(): boolean {
    return !this.produktDetails ||
      !this.produktDetails.spFehlerStatus &&
      !this.produktDetails.spFehlermeldung;
  }

  ngOnDestroy() {
    this.observableBeobachter.forEach(beobachter => beobachter.unsubscribe());
    this.store.dispatch(ladeInitialZustandBarcodeScan());
    this.ladeInitialZustandRadscan();
  }

  scanStarten() {
//    this.leereVerbrauchsbuchungErgebnis();
    this.scanFormularComponent.scanStarten();
  }

  barcodeGescannt(code: string) {

    console.log("verbrauchsbuchung-formular.components - barcodeGescannt " + code);

    this.radsatz.setValue(code);

    this.barcodeVerarbeitungService.verarbeiteBarcode(code);
    this.ladeInitialZustandRadscan();
  }

  buchen(formulardaten) {
    this.ladeInitialZustandRadscan();

    console.log("radscan.buchen start");

    if (this.formular.invalid) {
      this.radsatz.markAsDirty();

      console.log("radscan.buchen invalid");

      // this.oeffneMeldungenDialog();
      return;
    }

    let anfrage: RadscanAnfrage = {
      token: this.autorisierungService.benutzerAutorisierung.token,
      aufNr: this.radsatz.value
    };

    console.log("radscan.buchen starteRadscan");

    this.store.dispatch(starteRadscan(anfrage));

    console.log("radscan.buchen nach dispatch");

  }


  private ladeInitialZustandRadscan() {
    this.store.dispatch(ladeInitialZustandRadscan());
  }

  geheZurueckZurNavigation() {
    this.router.navigate(['/navigation'], { relativeTo: this.route.root});
  }

    // Neue Methode, um zur Reifenart-Seite zu navigieren
    geheZurReifenart(): void {
      const radsatzValue = this.formular.get('radsatz').value;
      this.router.navigate(['/reifenart', radsatzValue]);
    }
}
