import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppZustand } from '../../shared/interfaces/app-zustand';
import { RadscanZustand } from './interfaces/radscan-zustand';
import { radscanFeatureName } from './radscan.reducer';

export const waehleRadscanFeature = createFeatureSelector<AppZustand, RadscanZustand>(radscanFeatureName);

export const waehleRadscanErgebnis = createSelector(
  waehleRadscanFeature,
  zustand => zustand.radscanErgebnis
);

export const waehleImZugriff = createSelector(
  waehleRadscanFeature,
  zustand => zustand.imZugriff
);
