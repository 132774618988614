import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DotComponent  } from './dot.component';  // Importiere die FelgenartComponent

const routes: Routes = [
  { path: 'dot', component: DotComponent },  // Definiere die Route für Felgenart
];

@NgModule({
  imports: [RouterModule.forChild(routes)],  // Verwende `forChild` für Feature-Routing
  exports: [RouterModule]
})
export class DotRoutingModule { }
