import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { lagerortBarcodeInhaltsformat, lieferantBarcodeInhaltsformat, produktBarcodeInhaltsformat, radsatzBarcodeInhaltsformat } from 'src/app/shared/konstanten';
import { AutorisierungService } from '../../backend-zugriff/autorisierung/autorisierung.service';
import { ladeInitialZustand as ladeInitialZustandBarcodeScan } from '../../barcode-scan/barcode-scan.actions';
import { waehleLagerortDetails, waehleLagerortImZugriff, waehleLieferantDetails, waehleLieferantImZugriff, waehleProduktDetails, waehleProduktImZugriff } from '../../barcode-scan/barcode-scan.selectors';
import { BarcodeVerarbeitungService } from '../../barcode-scan/barcode-verarbeitung.service';
import { LagerortDetails } from '../../barcode-scan/interfaces/lagerort-details';
import { LieferantDetails } from '../../barcode-scan/interfaces/lieferant-details';
import { ProduktDetails } from '../../barcode-scan/interfaces/produkt-details';
import { ScanFormularComponent } from '../../barcode-scan/scan-formular/scan-formular.component';
import { KonfigurationService } from '../../konfiguration/konfiguration.service';
import { AppZustand } from '../../shared/interfaces/app-zustand';
import { datenBekanntValidierer } from '../../shared/validierer/daten-bekannt-validierer';
import { zahlValidierer } from '../../shared/validierer/zahl-validierer';
import { VerbrauchsbuchungAnfrage } from '../interfaces/verbrauchsbuchung-anfrage';
import { VerbrauchsbuchungErgebnis } from '../interfaces/verbrauchsbuchung-ergebnis';
import { VerbrauchsbuchungZustand } from '../interfaces/verbrauchsbuchung-zustand';
import { ladeInitialZustand as ladeInitialZustandVerbrauchsbuchung, starteVerbrauchsbuchung } from '../verbrauchsbuchung.actions';
import { waehleImZugriff as waehleVerbrauchsbuchungImZugriff, waehleVerbrauchsbuchungFeature } from '../verbrauchsbuchung.selectors';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lagermanager-verbrauchsbuchung',
  templateUrl: './verbrauchsbuchung-formular.component.html',
  styleUrls: ['./verbrauchsbuchung-formular.component.scss']
})
export class VerbrauchsbuchungFormularComponent implements OnInit, OnDestroy {
  private observableBeobachter: Subscription[] = [];

  @ViewChild(ScanFormularComponent, { static: false })
  private scanFormularComponent: ScanFormularComponent;

  @ViewChild('meldungen', { static: false })
  private meldungen;


  erwarteteBarcodeInhaltsformate = [
    lagerortBarcodeInhaltsformat,
    lieferantBarcodeInhaltsformat,
    produktBarcodeInhaltsformat,
    radsatzBarcodeInhaltsformat
  ];
  // erwarteteBarcodeInhaltsformate = [];

  get istScanAktiv() {
    return this.scanFormularComponent && this.scanFormularComponent.istScanAktiv;
  }

  verbrauchsbuchungImZugriff: boolean;
  private verbrauchsbuchungErgebnisDaten: VerbrauchsbuchungErgebnis = {
    erfolgreichDurchgefuehrt: false,
    spFehlerStatus: null,
    spFehlermeldung: null
  };

  get barcodeScanImZugriff() {
    return this.lagerortBarcodeScanImZugriff || this.lieferantBarcodeScanImZugriff || this.produktBarcodeScanImZugriff;
  }

  private lagerortBarcodeScanImZugriff = false;
  private lieferantBarcodeScanImZugriff = false;
  private produktBarcodeScanImZugriff = false;


  get verbrauchsbuchungErgebnis() {
    return this.verbrauchsbuchungErgebnisDaten;
  }

  get lieferantSichtbar() {
    let sichtbar = this.konfigurationService.konfiguration && this.konfigurationService.konfiguration.verbrauchsbuchung.lieferantSichtbar;
    if (sichtbar) {
      this.lieferant.enable();
    } else {
      this.lieferant.disable();
    }
    return sichtbar;
  }

  formular: FormGroup;

  private lagerortDetails: LagerortDetails;
  private lieferantDetails: LieferantDetails;
  private produktDetails: ProduktDetails;

  get radsatz() {
    return this.formular.get('radsatz');
  }

  get lieferant() {
    return this.formular.get('lieferant');
  }

  get lagerort() {
    return this.formular.get('lagerort');
  }

  get produkt() {
    return this.formular.get('produkt');
  }

  get menge() {
    return this.formular.get('menge');
  }

  constructor(private barcodeVerarbeitungService: BarcodeVerarbeitungService, private formBuilder: FormBuilder, private store: Store<AppZustand>,
    private konfigurationService: KonfigurationService, private autorisierungService: AutorisierungService, private modalService: NgbModal,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.formular = this.formBuilder.group({
      radsatz: new FormControl(''),
      lieferant: new FormControl('', {
        validators: [Validators.required, datenBekanntValidierer(() => this.lieferantDetailsBekannt())]
      }),
      lagerort: new FormControl('', {
        validators: [Validators.required, datenBekanntValidierer(() => this.lagerortDetailsBekannt())]
      }),
      produkt: new FormControl('', {
        validators: [Validators.required, datenBekanntValidierer(() => this.produktDetailsBekannt())]
      }),
      menge: new FormControl('', {
        validators: [Validators.required, zahlValidierer, Validators.min(1)]
      })
    });

    let lagerortImZugriff$ = this.store.select(waehleLagerortImZugriff);
    let lagerortDetails$ = this.store.select(waehleLagerortDetails);
    let lieferantImZugriff$ = this.store.select(waehleLieferantImZugriff);
    let lieferantDetails$ = this.store.select(waehleLieferantDetails);
    let produktImZugriff$ = this.store.select(waehleProduktImZugriff);
    let produktDetails$ = this.store.select(waehleProduktDetails);
    let verbrauchsbuchungImZugriff$ = this.store.select(waehleVerbrauchsbuchungImZugriff);
    let verbrauchsbuchungFeature$ = this.store.select(waehleVerbrauchsbuchungFeature);

    this.observableBeobachter.push(
      combineLatest(lagerortImZugriff$, lagerortDetails$, this.zusamenfasserImZugriffUndLagerortDetails)
        .subscribe(ergebnis => this.verarbeiteLagerortDetails(ergebnis))
    );

    this.observableBeobachter.push(
      combineLatest(lieferantImZugriff$, lieferantDetails$, this.zusammenfasserImZugriffUndLieferantDetails)
        .subscribe(ergebnis => this.verarbeiteLieferantDetails(ergebnis))
    );

    this.observableBeobachter.push(
      combineLatest(produktImZugriff$, produktDetails$, this.zusammenfasserImZugriffUndProduktDetails)
        .subscribe(ergebnis => this.verarbeiteProduktDetails(ergebnis))
    );

    this.observableBeobachter.push(
      verbrauchsbuchungFeature$.subscribe(ergebnis => this.verarbeiteVerbrauchsbuchungFeature(ergebnis))
    );

    this.observableBeobachter.push(
      verbrauchsbuchungImZugriff$.subscribe(ergebnis => {
        this.aktiviereFormular(ergebnis);
      })
    );

    this.observableBeobachter.push(
      this.menge.valueChanges.subscribe(() => {
        this.leereVerbrauchsbuchungErgebnis();
      })
    );
  }

  private leereVerbrauchsbuchungErgebnis(): void {
    this.verbrauchsbuchungErgebnis.erfolgreichDurchgefuehrt = false;
    this.verbrauchsbuchungErgebnis.spFehlerStatus = null;
    this.verbrauchsbuchungErgebnis.spFehlermeldung = null;
  }

  private oeffneMeldungenDialog() {
    this.modalService.open(this.meldungen, { scrollable: true, centered: true });
  }

  private zusamenfasserImZugriffUndLagerortDetails(imZugriff: boolean, lagerortDetails: LagerortDetails) {
    return {
      imZugriff: imZugriff,
      lagerortDetails: lagerortDetails
    };
  }

  private verarbeiteLagerortDetails(zusammenfassung: { imZugriff: boolean, lagerortDetails: LagerortDetails }) {
    this.lagerortBarcodeScanImZugriff = zusammenfassung.imZugriff;
    if (!zusammenfassung.imZugriff && (zusammenfassung.lagerortDetails.lagerortId || zusammenfassung.lagerortDetails.spFehlerStatus)) {
      this.lagerortDetails = zusammenfassung.lagerortDetails;
      this.lagerort.markAsDirty();
      if (this.lagerortDetails.lagerortId && !this.lagerortDetails.spFehlerStatus) {
        this.lagerort.setValue(`${this.lagerortDetails.bezeichnung} R${this.lagerortDetails.reihe} S${this.lagerortDetails.staender} F${this.lagerortDetails.fach}`);
      } else {
        this.lagerort.setValue('?');
        this.oeffneMeldungenDialog();
      }
    }
  }

  private zusammenfasserImZugriffUndLieferantDetails(imZugriff: boolean, lieferantDetails: LieferantDetails) {
    return {
      imZugriff: imZugriff,
      lieferantDetails: lieferantDetails
    };
  }

  private verarbeiteLieferantDetails(zusammenfassung: { imZugriff: boolean; lieferantDetails: LieferantDetails; }) {
    this.lieferantBarcodeScanImZugriff = zusammenfassung.imZugriff;
    if (!zusammenfassung.imZugriff && (zusammenfassung.lieferantDetails.lieferantId || zusammenfassung.lieferantDetails.spFehlerStatus)) {
      this.lieferantDetails = zusammenfassung.lieferantDetails;
      this.lieferant.markAsDirty();
      if (this.lieferantDetails.lieferantId && !this.lieferantDetails.spFehlerStatus) {
        this.lieferant.setValue(this.lieferantDetails.bezeichnung);
      } else {
        this.lieferant.setValue('?');
        this.oeffneMeldungenDialog();
      }
    }
  }

  private zusammenfasserImZugriffUndProduktDetails(imZugriff: boolean, produktDetails: ProduktDetails) {
    return {
      imZugriff: imZugriff,
      produktDetails: produktDetails
    };
  }

  private verarbeiteProduktDetails(zusammenfassung: { imZugriff: boolean; produktDetails: ProduktDetails; }) {
    this.produktBarcodeScanImZugriff = zusammenfassung.imZugriff;
    if (!zusammenfassung.imZugriff && (zusammenfassung.produktDetails.produktId || zusammenfassung.produktDetails.spFehlerStatus)) {
      this.produktDetails = zusammenfassung.produktDetails;
      this.produkt.markAsDirty();
      if (this.produktDetails.produktId && !this.produktDetails.spFehlerStatus) {
        this.produkt.setValue(this.produktDetails.bezeichnung);
      } else {
        this.produkt.setValue('?');
        this.oeffneMeldungenDialog();
      }
    }
  }

  private aktiviereFormular(imZugriff: boolean) {
    this.verbrauchsbuchungImZugriff = imZugriff;
    if (imZugriff) {
      this.formular.disable({ emitEvent: false });
    } else {
      this.formular.enable({ emitEvent: false });
    }
  }

  lagerortDetailsBekannt(): boolean {
    return !this.lagerortDetails ||
      !this.lagerortDetails.spFehlerStatus &&
      !this.lagerortDetails.spFehlermeldung;
  }

  lieferantDetailsBekannt(): boolean {
    return !this.lieferantDetails ||
      !this.lieferantDetails.spFehlerStatus &&
      !this.lieferantDetails.spFehlermeldung;
  }

  produktDetailsBekannt(): boolean {
    return !this.produktDetails ||
      !this.produktDetails.spFehlerStatus &&
      !this.produktDetails.spFehlermeldung;
  }

  ngOnDestroy() {
    this.observableBeobachter.forEach(beobachter => beobachter.unsubscribe());
    this.store.dispatch(ladeInitialZustandBarcodeScan());
    this.ladeInitialZustandVerbrauchsbuchung();
  }

  scanStarten() {
    this.leereVerbrauchsbuchungErgebnis();
    this.scanFormularComponent.scanStarten();
  }

  barcodeGescannt(code: string) {

    console.log("verbrauchsbuchung-formular.components - barcodeGescannt " + code);

    this.radsatz.setValue(code);

    this.barcodeVerarbeitungService.verarbeiteBarcode(code);
    this.ladeInitialZustandVerbrauchsbuchung();
  }

  buchen(formulardaten) {
    this.ladeInitialZustandVerbrauchsbuchung();

    if (this.formular.invalid) {
      this.lieferant.markAsDirty();
      this.lagerort.markAsDirty();
      this.produkt.markAsDirty();
      this.menge.markAsDirty();
      this.oeffneMeldungenDialog();
      return;
    }

    let anfrage: VerbrauchsbuchungAnfrage = {
      token: this.autorisierungService.benutzerAutorisierung.token,
      lagerortId: this.lagerortDetails.lagerortId,
      lieferantId: this.konfigurationService.konfiguration.verbrauchsbuchung.lieferantSichtbar ? this.lieferantDetails.lieferantId : null,
      produktId: this.produktDetails.produktId,
      menge: +formulardaten.menge,
      reihe: this.lagerortDetails.reihe,
      staender: this.lagerortDetails.staender,
      fach: this.lagerortDetails.fach
    };

    this.store.dispatch(starteVerbrauchsbuchung(anfrage));
  }

  private verarbeiteVerbrauchsbuchungFeature(ergebnis: VerbrauchsbuchungZustand) {
    if (!ergebnis.imZugriff) {
      if (ergebnis.verbrauchsbuchungErgebnis.erfolgreichDurchgefuehrt) {
        if (!this.konfigurationService.konfiguration.verbrauchsbuchung.lieferantMerken) {
          this.lieferant.setValue('');
          this.lieferantDetails = null;
        }
        if (!this.konfigurationService.konfiguration.verbrauchsbuchung.lagerortMerken) {
          this.lagerort.setValue('');
          this.lagerortDetails = null;
        }
        this.produkt.setValue('');
        this.produktDetails = null;
        this.menge.setValue('');

        this.formular.markAsUntouched();
        this.formular.markAsPristine();
        this.oeffneMeldungenDialog();
      } else if (ergebnis.verbrauchsbuchungErgebnis.spFehlerStatus != null) {
        this.oeffneMeldungenDialog();
      }
      this.verbrauchsbuchungErgebnisDaten = ergebnis.verbrauchsbuchungErgebnis;
    }
  }

  private ladeInitialZustandVerbrauchsbuchung() {
    this.store.dispatch(ladeInitialZustandVerbrauchsbuchung());
  }

  geheZurueckZurNavigation() {
    this.router.navigate(['/navigation/auslagern'], { relativeTo: this.route.root});
  }
}
