import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { ladeInitialZustand as ladeInitialZustandBarcodeScan } from '../../barcode-scan/barcode-scan.actions';
import { ladeInitialZustand as ladeInitialZustandKonfiguration, ladeKonfiguration } from '../../konfiguration/konfiguration.actions';
import { AppZustand } from '../../shared/interfaces/app-zustand';
import { localStorageFirmenKuerzel } from '../../shared/konstanten';
import { beginneLogin, ermittleFirmenbackendUrl, loggeAus, pruefeAnmeldedaten } from '../backend-zugriff.actions';
import { waehleBenutzerAutorisierung, waehleFirmenbackendUrl, waehleLoginImZugriff } from '../backend-zugriff.selectors';
import { Anmeldedaten } from '../interfaces/anmeldedaten';
import { BenutzerAutorisierung } from '../interfaces/benutzer-autorisierung';
import { FirmenbackendUrlAnfrage } from '../interfaces/firmenbackend-url-anfrage';
import { FirmenbackendUrlErgebnis } from '../interfaces/firmenbackend-url-ergebnis';

@Injectable()
export class AutorisierungService {
  private _firmenbackendUrl: FirmenbackendUrlErgebnis;

  get firmenbackendUrl() {
    return this._firmenbackendUrl;
  }

  private _benutzerAutorisierung: BenutzerAutorisierung;

  get istEingeloggt() {
    return this._benutzerAutorisierung && !!this._benutzerAutorisierung.token;
  }

  get benutzerAutorisierung() {
    return this._benutzerAutorisierung;
  }

  private loginBeobachter: Subscription;

  constructor(private store: Store<AppZustand>, private router: Router, private route: ActivatedRoute) {
    this.store.select(waehleFirmenbackendUrl).subscribe(wert => {
      this._firmenbackendUrl = wert;
    });
    this.store.select(waehleBenutzerAutorisierung).subscribe(wert => {
      this._benutzerAutorisierung = wert;

      if (!!this._benutzerAutorisierung.token && this.loginBeobachter) {
        this.loginBeobachter.unsubscribe();
      }
    });
  }

  loggeEin(formulardaten: Anmeldedaten) {
    this.store.dispatch(beginneLogin());

    let firmenbackendUrlAnfrage: FirmenbackendUrlAnfrage = {
      firmenkuerzel: formulardaten.firmenKuerzel
    };
    this.store.dispatch(ermittleFirmenbackendUrl(firmenbackendUrlAnfrage));

    let loginImZugriff$ = this.store.select(waehleLoginImZugriff);
    let firmenbackendUrl$ = this.store.select(waehleFirmenbackendUrl);

    let zusammenfasser = (loginImZugriff: boolean, firmenbackendUrl: FirmenbackendUrlErgebnis) => {
      return {
        loginImZugriff,
        firmenbackendUrl
      };
    };

    if (this.loginBeobachter) {
      this.loginBeobachter.unsubscribe();
    }
    this.loginBeobachter = combineLatest(loginImZugriff$, firmenbackendUrl$, zusammenfasser).subscribe(werte => {
      if (werte.loginImZugriff && !!werte.firmenbackendUrl && !!werte.firmenbackendUrl.firmenbackendUrl && !werte.firmenbackendUrl.imZugriff) {
        localStorage.setItem(localStorageFirmenKuerzel, formulardaten.firmenKuerzel);

        this.store.dispatch(pruefeAnmeldedaten({
          anmeldedaten: formulardaten,
          firmenbackendUrl: werte.firmenbackendUrl
        }));
        this.store.dispatch(ladeKonfiguration());
        if (this.loginBeobachter) {
          this.loginBeobachter.unsubscribe();
        }
      }
    });
  }

  loggeAus() {
    this.store.dispatch(ladeInitialZustandKonfiguration());
    this.store.dispatch(ladeInitialZustandBarcodeScan());
    this.store.dispatch(loggeAus());
    this.router.navigate(['/'], { relativeTo: this.route });
  }
}
