import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BackendZugriffModule } from '../backend-zugriff/backend-zugriff.module';
// import { BarcodeScanModule } from '../barcode-scan/barcode-scan.module';
import { SharedModule } from '../shared/shared.module';
import { AwtwaFormularComponent } from './awtwa-formular/awtwa-formular.component';
import { AwtwaRoutingModule } from './awtwa-routing.module';
import { AwtwaEffects } from './awtwa.effects';
import { wabuchungFeatureName, bewegungsbuchungReducer } from './awtwa.reducer';

@NgModule({
  declarations: [AwtwaFormularComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule,
    BackendZugriffModule,
    // BarcodeScanModule,
    ReactiveFormsModule,
    StoreModule.forFeature(wabuchungFeatureName, bewegungsbuchungReducer),
    EffectsModule.forFeature([AwtwaEffects]),
    AwtwaRoutingModule
  ]
})
export class AwtwaModule { }
