import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { AutorisierungService } from '../backend-zugriff/autorisierung/autorisierung.service';
import { UrlService } from '../shared/url-service/url.service';
import { VerbrauchsbuchungAnfrage } from './interfaces/verbrauchsbuchung-anfrage';
import { VerbrauchsbuchungErgebnis } from './interfaces/verbrauchsbuchung-ergebnis';
import { starteVerbrauchsbuchung, verbrauchsbuchungAbgeschlossen } from './verbrauchsbuchung.actions';

@Injectable()
export class VerbrauchsbuchungEffects {
  starteVerbrauchsbuchung$ = createEffect(() => this.action$.pipe(
    ofType(starteVerbrauchsbuchung.type),
    mergeMap((actionDaten: VerbrauchsbuchungAnfrage) => {
      return this.http.post<VerbrauchsbuchungErgebnis>(this.urlService.erstelleUrl(this.autorisierungService.firmenbackendUrl.firmenbackendUrl, 'api/verbrauchsbuchung'), actionDaten).pipe(
        map(ergebnisse => verbrauchsbuchungAbgeschlossen(ergebnisse))
      );
    })
  ));

  constructor(private action$: Actions, private http: HttpClient, private urlService: UrlService, private autorisierungService: AutorisierungService) {}
}
