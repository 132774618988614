import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReifenprofiltypComponent  } from './reifenprofiltyp.component';  // Importiere die FelgenartComponent

const routes: Routes = [
  { path: 'reifenprofiltyp', component: ReifenprofiltypComponent },  // Definiere die Route für Felgenart
];

@NgModule({
  imports: [RouterModule.forChild(routes)],  // Verwende `forChild` für Feature-Routing
  exports: [RouterModule]
})
export class ReifenprofiltypRoutingModule { }
