import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BackendZugriffModule } from '../backend-zugriff/backend-zugriff.module';
// import { BarcodeScanModule } from '../barcode-scan/barcode-scan.module';
import { SharedModule } from '../shared/shared.module';
import { AbholungComponent } from './abholung.component';
import { AbholungRoutingModule } from './abholung-routing.module';
import { AbholungEffects } from './abholung.effects';
import { AbholungFeatureName, bewegungsbuchungReducer } from './abholung.reducer';

@NgModule({
  declarations: [AbholungComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule,
    BackendZugriffModule,
    // BarcodeScanModule,
    ReactiveFormsModule,
    StoreModule.forFeature(AbholungFeatureName, bewegungsbuchungReducer),
    EffectsModule.forFeature([AbholungEffects]),
    AbholungRoutingModule
  ]
})
export class AbholungModule { }
