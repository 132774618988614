import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Konfiguration } from '../shared/interfaces/konfiguration';
import { konfigurationGeladen, ladeKonfiguration } from './konfiguration.actions';

@Injectable()
export class KonfigurationEffects {
  ladeKonfiguration$ = createEffect(() => this.action$.pipe(
    ofType(ladeKonfiguration.type),
    mergeMap(() => {
      return of(konfigurationGeladen({
        verbrauchsbuchung: {
          lieferantSichtbar: true,
          lieferantMerken: true,
          lagerortMerken: true
        }
      } as Konfiguration));
    })
  ));

  constructor(private action$: Actions) {}
}
