import { Component, Input } from '@angular/core';

@Component({
  selector: 'lagermanager-auslagern-symbol',
  templateUrl: './auslagern-symbol.component.html',
  styleUrls: ['./auslagern-symbol.component.scss']
})
export class AuslagernSymbolComponent {
  @Input('class') klassen = '';
}
