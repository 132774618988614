import { Component, OnInit } from '@angular/core';
import { AutorisierungService } from '../../backend-zugriff/autorisierung/autorisierung.service';


@Component({
  selector: 'lagermanager-hauptmenue',
  templateUrl: './hauptmenue.component.html',
  styleUrls: ['./hauptmenue.component.scss']
})
export class HauptmenueComponent implements OnInit {

  constructor(private autorisierungService : AutorisierungService) { }

  ngOnInit() {
  }

  loggeAus() {
    this.autorisierungService.loggeAus();
  }

  getBenutzer():string {

    return this.autorisierungService.benutzerAutorisierung.anzeigename;
  }

  abholung_sichtbar():boolean {
    return true;
  }
}
