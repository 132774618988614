import { createReducer, on } from '@ngrx/store';
import { WabuchungErgebnis } from './interfaces/wabuchung-ergebnis';
import { WabuchungZustand } from './interfaces/wabuchung-zustand';
import { ladeInitialZustand, starteBewegungsbuchung, bewegungsBuchungAbgeschlossen } from './awtwa.actions';
import { backendNichtErreichbar, unerwarteterBackendFehler } from '../backend-zugriff/backend-zugriff.actions';

export const wabuchungFeatureName = 'wabuchung';

export const initialZustand: WabuchungZustand = {
  wabuchungErgebnis: {
    erfolgreichDurchgefuehrt: false,
    spFehlerStatus: null,
    spFehlermeldung: null
  },
  imZugriff: false
};

function onStarteBewegungsbuchung(zustand: WabuchungZustand): WabuchungZustand {
  // console.log ("onStarteBewegungsbuchung");

  return {
    ...initialZustand,
    imZugriff: true
  };
}

function onBewegungsbuchungAbgeschlossen(zustand: WabuchungZustand, actionDaten: WabuchungErgebnis): WabuchungZustand {
  // console.log ("awtwa.reducer onBewegungsbuchungAbgeschlossen");

  return {
    ...zustand,
    wabuchungErgebnis: {
      ...actionDaten,
      erfolgreichDurchgefuehrt: !actionDaten.spFehlerStatus
    },
    imZugriff: false
  };
}

function onLadeInitialZustand(zustand: WabuchungZustand): WabuchungZustand {
  return initialZustand;
}

function loescheImZugriffFlags(zustand: WabuchungZustand): WabuchungZustand {
  return {
    ...zustand,
    imZugriff: false
  };
}

const reducer = createReducer(initialZustand,
  on(starteBewegungsbuchung, onStarteBewegungsbuchung),
  on(bewegungsBuchungAbgeschlossen, onBewegungsbuchungAbgeschlossen),
  on(ladeInitialZustand, onLadeInitialZustand),
  on(backendNichtErreichbar, loescheImZugriffFlags),
  on(unerwarteterBackendFehler, loescheImZugriffFlags)
);

export function bewegungsbuchungReducer(zustand, actionDaten) {
  // console.log ("awtwa.reducer.ts bewegungsbuchungReducer erzeugt");
  return reducer(zustand, actionDaten);
}
