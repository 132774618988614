import { Component, OnDestroy, OnInit, ViewChild, ElementRef, AfterViewInit  } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { lagerortBarcodeInhaltsformat, lieferantBarcodeInhaltsformat, produktBarcodeInhaltsformat } from 'src/app/shared/konstanten';
import { AutorisierungService } from '../../backend-zugriff/autorisierung/autorisierung.service';
import { ladeInitialZustand as ladeInitialZustandBarcodeScan } from '../../barcode-scan/barcode-scan.actions';
// Barcode-SCAN: import { waehleLagerortDetails, waehleLagerortImZugriff, waehleLieferantDetails, waehleLieferantImZugriff, waehleProduktDetails, waehleProduktImZugriff } from '../../barcode-scan/barcode-scan.selectors';
import { BarcodeVerarbeitungService } from '../../barcode-scan/barcode-verarbeitung.service';
import { LagerortDetails } from '../../barcode-scan/interfaces/lagerort-details';
import { LieferantDetails } from '../../barcode-scan/interfaces/lieferant-details';
import { ProduktDetails } from '../../barcode-scan/interfaces/produkt-details';
import { ScanFormularComponent } from '../../barcode-scan/scan-formular/scan-formular.component';
import { KonfigurationService } from '../../konfiguration/konfiguration.service';
import { AppZustand } from '../../shared/interfaces/app-zustand';
import { datenBekanntValidierer } from '../../shared/validierer/daten-bekannt-validierer';
import { zahlValidierer } from '../../shared/validierer/zahl-validierer';
import { BewegungsbuchungAnfrage } from '../interfaces/webuchung-anfrage';
import { WebuchungErgebnis } from '../interfaces/webuchung-ergebnis';
import { WebuchungZustand } from '../interfaces/webuchung-zustand';
import { ladeInitialZustand as ladeInitialZustandBewegungsbuchung, starteBewegungsbuchung } from '../awtwe.actions';
import { waehleImZugriff as waehleBewegungsbuchungImZugriff, waehleBewegungsbuchungFeature } from '../awtwe.selectors';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-awtwe-formular',
  templateUrl: './awtwe-formular.component.html',
  styleUrls: ['./awtwe-formular.component.scss']
})

export class AwtweFormularComponent implements OnInit, OnDestroy, AfterViewInit {
  private observableBeobachter: Subscription[] = [];

  @ViewChild(ScanFormularComponent, { static: false })
  private scanFormularComponent: ScanFormularComponent;

  @ViewChild('meldungen', { static: false })
  private meldungen;

  @ViewChild('focusRack', { static: false }) focusRack: ElementRef;


  get istScanAktiv() {
    return this.scanFormularComponent && this.scanFormularComponent.istScanAktiv;
  }

  bewegungsbuchungImZugriff: boolean;
  private bewegungsbuchungErgebnisDaten: WebuchungErgebnis = {
    erfolgreichDurchgefuehrt: false,
    spFehlerStatus: null,
    spFehlermeldung: null
  };


  get bewegungsbuchungErgebnis() {
    return this.bewegungsbuchungErgebnisDaten;
  }


  formular: FormGroup;

  get rack() {
    return this.formular.get('rack');
  }


  constructor(private barcodeVerarbeitungService: BarcodeVerarbeitungService, private formBuilder: FormBuilder, private store: Store<AppZustand>,
    private konfigurationService: KonfigurationService, private autorisierungService: AutorisierungService, private modalService: NgbModal,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    // hier ergänzen wenn neue Felder mit formControlName aufgenommen werden
    this.formular = this.formBuilder.group({
      rack: new FormControl(''),
    });


    let BewegungsbuchungImZugriff$ = this.store.select(waehleBewegungsbuchungImZugriff);
    let BewegungsbuchungFeature$ = this.store.select(waehleBewegungsbuchungFeature);


    this.observableBeobachter.push(
      BewegungsbuchungFeature$.subscribe(ergebnis => this.verarbeiteBewegungsbuchungFeature(ergebnis))
    );

    this.observableBeobachter.push(
      BewegungsbuchungImZugriff$.subscribe(ergebnis => {
        this.aktiviereFormular(ergebnis);
      })
    );

  }


  ngAfterViewInit() { // Focus in erstes Feld setzen
    this.focusRack.nativeElement.focus();
  }


  private leereBewegungsbuchungErgebnis(): void {
    this.bewegungsbuchungErgebnis.erfolgreichDurchgefuehrt = false;
    this.bewegungsbuchungErgebnis.spFehlerStatus = null;
    this.bewegungsbuchungErgebnis.spFehlermeldung = null;
  }

  private oeffneMeldungenDialog() {
    // console.log ("awteinlagern-formular.component oeffneMeldungenDialog" + this.meldungen);
    this.modalService.open(this.meldungen, { scrollable: true, centered: true });

    this.focusRack.nativeElement.focus(); // funktioniert hier nicht
  }

  private zusamenfasserImZugriffUndLagerortDetails(imZugriff: boolean, lagerortDetails: LagerortDetails) {
    return {
      imZugriff: imZugriff,
      lagerortDetails: lagerortDetails
    };
  }


  private zusammenfasserImZugriffUndProduktDetails(imZugriff: boolean, produktDetails: ProduktDetails) {
    return {
      imZugriff: imZugriff,
      produktDetails: produktDetails
    };
  }


  private aktiviereFormular(imZugriff: boolean) {
    this.bewegungsbuchungImZugriff = imZugriff;
    if (imZugriff) {
      // this.formular.disable({ emitEvent: false });
      // RD 20200722 wir sperren das Formular einfach nie, damit Focus weiterhin funktioniert
      this.formular.enable({ emitEvent: false });
    } else {
      this.formular.enable({ emitEvent: false });
    }
  }

  ngOnDestroy() {
    this.observableBeobachter.forEach(beobachter => beobachter.unsubscribe());
    this.store.dispatch(ladeInitialZustandBarcodeScan());
    this.ladeInitialZustandBewegungsbuchung();
  }

  scanStarten() {
    this.leereBewegungsbuchungErgebnis();
    this.scanFormularComponent.scanStarten();
  }

  barcodeGescannt(code: string) {
    this.barcodeVerarbeitungService.verarbeiteBarcode(code);
    this.ladeInitialZustandBewegungsbuchung();
  }

  buchen(formulardaten) {
    this.ladeInitialZustandBewegungsbuchung();

    // console.log("starte buchen" + formulardaten);

    if (this.formular.invalid) {
      // console.log("buchen invalid!");

      this.rack.markAsDirty();

      this.oeffneMeldungenDialog();
      return;
    }


    let buchungsdatum = new Date(); // Typescript Datum ist UTC, wir müssen in GMT+2 umrechnen:
    buchungsdatum = new Date( buchungsdatum.getTime() + (buchungsdatum.getTimezoneOffset() * 1000) );
    // console.log("buchen Zeit " + buchungsdatum.toString() );

    let anfrage: BewegungsbuchungAnfrage = {
      token: this.autorisierungService.benutzerAutorisierung.token,
      aufNr: '0',
      BewaID: -2, /* Bewegung Ersterfassung */
      Rollwagen: this.rack.value,
      Kennzeichen: '',
      Benutzer: this.autorisierungService.benutzerAutorisierung.anzeigename,
      RadAnzahl: 4,
      Daten: '',
      paramHaendler_ID: 0,
      bewd_Zeit: buchungsdatum
    };

    // console.log("buchen BewegungsbuchungAnfrage erzeugt");

    this.store.dispatch(starteBewegungsbuchung(anfrage));
  }

  private verarbeiteBewegungsbuchungFeature(ergebnis: WebuchungZustand) {
    // console.log("awteinlagern-formular.component.ts verarbeiteBewegungsbuchungFeature imZugriff: " + ergebnis.imZugriff + " erfolgreichDurchgefuehrt: " + ergebnis.bewegungsbuchungErgebnis.erfolgreichDurchgefuehrt);

    if (!ergebnis.imZugriff) {
      if (ergebnis.webuchungErgebnis.erfolgreichDurchgefuehrt) {

        this.rack.setValue('');

        this.formular.markAsUntouched();
        this.formular.markAsPristine();

        // "Die Bewegung wurde erfolgreich gebucht."

        this.focusRack.nativeElement.focus(); // funktioniert hier nicht

        this.oeffneMeldungenDialog();

        this.focusRack.nativeElement.focus(); // funktioniert hier nicht

      } else if (ergebnis.webuchungErgebnis.spFehlerStatus != null) {
        this.oeffneMeldungenDialog();
      }
      this.bewegungsbuchungErgebnisDaten = ergebnis.webuchungErgebnis;
    }
  }

  private ladeInitialZustandBewegungsbuchung() {
    this.store.dispatch(ladeInitialZustandBewegungsbuchung());
  }

  geheZurueckZurNavigation() {
    this.router.navigate(['/navigation'], { relativeTo: this.route.root});
  }
}
