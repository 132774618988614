import { createReducer, on } from '@ngrx/store';
import { ladeInitialZustand, ladeLagerortdetailsZuBarcode, ladeLieferantdetailsZuBarcode, ladeProduktdetailsZuBarcode, lagerortdetailsZuBarcodeGeladen, lieferantDetailsZuBarcodeGeladen, produktdetailsZuBarcodeGeladen } from './barcode-scan.actions';
import { BarcodeScanZustand } from './interfaces/barcode-scan-zustand';
import { LagerortBarcodeDaten } from './interfaces/lagerort-barcode-daten';
import { LagerortDetails } from './interfaces/lagerort-details';
import { LieferantDetails } from './interfaces/lieferant-details';
import { ProduktDetails } from './interfaces/produkt-details';
import { backendNichtErreichbar, unerwarteterBackendFehler } from '../backend-zugriff/backend-zugriff.actions';

export const barcodeScanFeatureName = 'barcodeScan';

export const initialZustand : BarcodeScanZustand = {
  lieferantImZugriff: false,
  lieferantDetails: {
    lieferantId: null,
    bezeichnung: null,
    spFehlerStatus: null,
    spFehlermeldung: null,
  },
  lagerortImZugriff: false,
  lagerortDetails: {
    lagerortId: null,
    bezeichnung: null,
    reihe: null,
    staender: null,
    fach: null,
    spFehlerStatus: null,
    spFehlermeldung: null
  },
  produktImZugriff: false,
  produktDetails: {
    produktId: null,
    bezeichnung: null,
    spFehlerStatus: null,
    spFehlermeldung: null
  }
};

function onLadeLagerortdetailsZuBarcode(zustand: BarcodeScanZustand, actionDaten: LagerortBarcodeDaten): BarcodeScanZustand {
  return {
    ...zustand,
    lagerortDetails: {
      ...initialZustand.lagerortDetails,
      reihe: actionDaten.reihe,
      staender: actionDaten.staender,
      fach: actionDaten.fach
    },
    lagerortImZugriff: true
  };
}

function onLagerortdetailsZuBarcodeGeladen(zustand: BarcodeScanZustand, actionDaten: LagerortDetails): BarcodeScanZustand {
  return {
    ...zustand,
    lagerortDetails: {
      ...zustand.lagerortDetails,
      ...actionDaten
    },
    lagerortImZugriff: false
  };
}

function onLadeLieferantdetailsZuBarcode(zustand: BarcodeScanZustand): BarcodeScanZustand {
  return {
    ...zustand,
    lieferantDetails: {
      ...initialZustand.lieferantDetails
    },
    lieferantImZugriff: true
  };
}

function onLieferantdetailsZuBarcodeGeladen(zustand: BarcodeScanZustand, actionDaten: LieferantDetails): BarcodeScanZustand {
  return {
    ...zustand,
    lieferantDetails: actionDaten,
    lieferantImZugriff: false
  };
}

function onLadeProduktdetailsZuBarcode(zustand: BarcodeScanZustand): BarcodeScanZustand {
  return {
    ...zustand,
    produktDetails: {
      ...initialZustand.produktDetails
    },
    produktImZugriff: true
  };
}

function onProduktdetailsZuBarcodeGeladen(zustand: BarcodeScanZustand, actionDaten: ProduktDetails): BarcodeScanZustand {
  return {
    ...zustand,
    produktDetails: actionDaten,
    produktImZugriff: false
  };
}

function onLadeInitialZustand(zustand: BarcodeScanZustand): BarcodeScanZustand {
  return initialZustand;
}

function loescheImZugriffFlags(zustand: BarcodeScanZustand): BarcodeScanZustand {
  return {
    ...zustand,
    lagerortImZugriff: false,
    lieferantImZugriff: false,
    produktImZugriff: false
  };
}

const reducer = createReducer(initialZustand,
  on(ladeLagerortdetailsZuBarcode, onLadeLagerortdetailsZuBarcode),
  on(lagerortdetailsZuBarcodeGeladen, onLagerortdetailsZuBarcodeGeladen),
  on(ladeLieferantdetailsZuBarcode, onLadeLieferantdetailsZuBarcode),
  on(lieferantDetailsZuBarcodeGeladen, onLieferantdetailsZuBarcodeGeladen),
  on(ladeProduktdetailsZuBarcode, onLadeProduktdetailsZuBarcode),
  on(produktdetailsZuBarcodeGeladen, onProduktdetailsZuBarcodeGeladen),
  on(ladeInitialZustand, onLadeInitialZustand),
  on(backendNichtErreichbar, loescheImZugriffFlags),
  on(unerwarteterBackendFehler, loescheImZugriffFlags)
);

export function barcodeScanReducer(zustand, action) {
  return reducer(zustand, action);
};
