import { createReducer, on } from '@ngrx/store';
import { WebuchungErgebnis } from './interfaces/webuchung-ergebnis';
import { WebuchungZustand } from './interfaces/webuchung-zustand';
import { ladeInitialZustand, starteBewegungsbuchung, bewegungsBuchungAbgeschlossen } from './awtwe.actions';
import { backendNichtErreichbar, unerwarteterBackendFehler } from '../backend-zugriff/backend-zugriff.actions';

export const webuchungFeatureName = 'webuchung';

export const initialZustand: WebuchungZustand = {
  webuchungErgebnis: {
    erfolgreichDurchgefuehrt: false,
    spFehlerStatus: null,
    spFehlermeldung: null
  },
  imZugriff: false
};

function onStarteBewegungsbuchung(zustand: WebuchungZustand): WebuchungZustand {
  // console.log ("onStarteBewegungsbuchung");

  return {
    ...initialZustand,
    imZugriff: true
  };
}

function onBewegungsbuchungAbgeschlossen(zustand: WebuchungZustand, actionDaten: WebuchungErgebnis): WebuchungZustand {
  // console.log ("awteinlagern.reducer onBewegungsbuchungAbgeschlossen");

  return {
    ...zustand,
    webuchungErgebnis: {
      ...actionDaten,
      erfolgreichDurchgefuehrt: !actionDaten.spFehlerStatus
    },
    imZugriff: false
  };
}

function onLadeInitialZustand(zustand: WebuchungZustand): WebuchungZustand {
  return initialZustand;
}

function loescheImZugriffFlags(zustand: WebuchungZustand): WebuchungZustand {
  return {
    ...zustand,
    imZugriff: false
  };
}

const reducer = createReducer(initialZustand,
  on(starteBewegungsbuchung, onStarteBewegungsbuchung),
  on(bewegungsBuchungAbgeschlossen, onBewegungsbuchungAbgeschlossen),
  on(ladeInitialZustand, onLadeInitialZustand),
  on(backendNichtErreichbar, loescheImZugriffFlags),
  on(unerwarteterBackendFehler, loescheImZugriffFlags)
);

export function bewegungsbuchungReducer(zustand, actionDaten) {
  // console.log ("awteinlagern.reducer.ts bewegungsbuchungReducer erzeugt");
  return reducer(zustand, actionDaten);
}
