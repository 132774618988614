import { Component, Input } from '@angular/core';

@Component({
  selector: 'lagermanager-info-symbol',
  templateUrl: './info-symbol.component.html',
  styleUrls: ['./info-symbol.component.scss']
})
export class InfoSymbolComponent {
  @Input('class') klassen = '';
}
