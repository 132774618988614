import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppZustand } from '../../shared/interfaces/app-zustand';
import { localStorageFirmenKuerzel } from '../../shared/konstanten';
import { AutorisierungService } from '../autorisierung/autorisierung.service';
import { waehleBenutzerAutorisierung, waehleFirmenbackendUrl, waehleLoginImZugriff, waehleLogoutImZugriff } from '../backend-zugriff.selectors';
import { Anmeldedaten } from '../interfaces/anmeldedaten';
import { BackendZugriffFehler } from '../interfaces/backend-zugriff-fehler';
import { BenutzerAutorisierung } from '../interfaces/benutzer-autorisierung';
import { FirmenbackendUrlErgebnis } from '../interfaces/firmenbackend-url-ergebnis';

@Component({
  selector: 'lagermanager-login',
  templateUrl: './login-formular.component.html',
  styleUrls: ['./login-formular.component.scss']
})
export class LoginFormularComponent implements OnInit, OnDestroy, AfterViewInit {
  private firmenbackendUrl: FirmenbackendUrlErgebnis;
  private benutzerAutorisierung: BenutzerAutorisierung;
  private backendZugriffFehler: BackendZugriffFehler;

  @ViewChild('meldungen', { static: false }) meldungen;
  @ViewChild('focusBenutzername', { static: false }) focusBenutzername: ElementRef;
  @ViewChild('focusPasswort', { static: false }) focusPasswort: ElementRef;

  get firmenKuerzelInkorrekt() {
    return this.firmenbackendUrl && !!this.firmenbackendUrl.spFehlerStatus;
  }

  get benutzernamePasswortInkorrekt() {
    return this.benutzerAutorisierung && this.benutzerAutorisierung.benutzernamePasswortInkorrekt;
  }

  get backendNichtErreichbar() {
    return this.backendZugriffFehler && this.backendZugriffFehler.backendNichtErreichbar;
  }

  get unerwarteterBackendFehler() {
    return this.backendZugriffFehler && this.backendZugriffFehler.unerwarteterBackendFehler;
  }

  loginImZugriff = false;
  loginForm: FormGroup;
  private logoutImZugriff = false;

  get benutzername() {
    return this.loginForm.get('benutzername');
  }

  get passwort() {
    return this.loginForm.get('passwort');
  }

  get firmenKuerzel() {
    return this.loginForm.get('firmenKuerzel');
  }

  private observableBeobachter: Subscription[] = [];

  constructor(private formBuilder: FormBuilder, private store: Store<AppZustand>, private router: Router, private autorisierungService: AutorisierungService,
    private modalService: NgbModal) { }

  private aktiviereFormular(imZugriff: boolean) {
    this.loginImZugriff = imZugriff;

    if (imZugriff) {
      this.loginForm.disable();
    } else {
      this.loginForm.enable();
    }
  }

  ngOnInit() {
    let firmenKuerzelWert = localStorage && localStorage.getItem(localStorageFirmenKuerzel) ? localStorage.getItem(localStorageFirmenKuerzel) : '';
    this.loginForm = this.formBuilder.group({
      firmenKuerzel: new FormControl(firmenKuerzelWert, {
        validators: [Validators.required],
        updateOn: 'submit'
      }),
      benutzername: new FormControl('', {
        validators: [Validators.required],
        updateOn: 'submit'
      }),
      passwort: new FormControl('', {
        validators: [Validators.required],
        updateOn: 'submit'
      })
    });

    this.observableBeobachter.push(this.store.select(waehleLoginImZugriff).subscribe(wert => this.aktiviereFormular(wert)));
    this.observableBeobachter.push(this.store.select(waehleLogoutImZugriff).subscribe(wert => this.logoutImZugriff = wert));

    this.observableBeobachter.push(
      this.store.select(waehleFirmenbackendUrl).subscribe(wert => {
        this.verarbeiteFirmenbackendUrl(wert);
      })
    );

    this.observableBeobachter.push(
      this.store.select(waehleBenutzerAutorisierung).subscribe(wert => {
        this.verarbeiteBenutzerAutorisierung(wert);
      })
    );
  }

  ngAfterViewInit() {
    this.focusBenutzername.nativeElement.focus();
  }


  fokussierePasswort(event) {
    // console.log("login-formular.component.ts fokussierePasswort");

    event.preventDefault();
    this.focusPasswort.nativeElement.focus();
    return false;
  }

  private verarbeiteBenutzerAutorisierung(wert: BenutzerAutorisierung) {
    this.benutzerAutorisierung = wert;
    if (!this.logoutImZugriff && wert && wert.token) {
      this.router.navigate(['/navigation']);
    }
    if (this.benutzernamePasswortInkorrekt) {
      this.passwort.setValue('');
      this.loginForm.markAsPristine();
      this.loginForm.markAsUntouched();
      this.oeffneMeldungenDialog();
    }
  }

  private verarbeiteFirmenbackendUrl(wert: FirmenbackendUrlErgebnis) {
    this.firmenbackendUrl = wert;
    if (this.firmenKuerzelInkorrekt) {
      this.oeffneMeldungenDialog();
    }
  }

  private oeffneMeldungenDialog() {
    this.modalService.open(this.meldungen, { scrollable: true, centered: true });
  }

  ngOnDestroy() {
    this.observableBeobachter.forEach(beobachter => beobachter.unsubscribe());
  }

  login(formulardaten: Anmeldedaten) {
    if (this.loginImZugriff) {
      return;
    }

    if (this.loginForm.invalid) {
      this.firmenKuerzel.markAsDirty();
      this.benutzername.markAsDirty();
      this.passwort.markAsDirty();
      this.oeffneMeldungenDialog();
      return;
    }

    this.autorisierungService.loggeEin(formulardaten);
  }

}
