import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppZustand } from '../shared/interfaces/app-zustand';
import { Konfiguration } from '../shared/interfaces/konfiguration';
import { KonfigurationModule } from './konfiguration.module';
import { waehleKonfigurationFeature } from './konfiguration.selectors';

@Injectable({
  providedIn: KonfigurationModule
})
export class KonfigurationService implements OnDestroy {
  private observableBeobachter: Subscription[] = [];

  konfiguration: Konfiguration;

  constructor(private store: Store<AppZustand>) {
    this.observableBeobachter.push(
      this.store.select(waehleKonfigurationFeature).subscribe(gewaehlteKonfiguration => {
        this.konfiguration = gewaehlteKonfiguration.konfiguration;
      })
    );
  }

  ngOnDestroy() {
    this.observableBeobachter.forEach((beobachter: Subscription) => beobachter.unsubscribe());
  }
}
