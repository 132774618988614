import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppZustand } from '../shared/interfaces/app-zustand';
import { lagerortBarcodeInhaltsformat, lieferantBarcodeInhaltsformat, produktBarcodeInhaltsformat } from '../shared/konstanten';
import { ladeLagerortdetailsZuBarcode, ladeLieferantdetailsZuBarcode, ladeProduktdetailsZuBarcode } from './barcode-scan.actions';
import { BarcodeScanModule } from './barcode-scan.module';
import { LagerortBarcodeDaten } from './interfaces/lagerort-barcode-daten';
import { LieferantBarcodeDaten } from './interfaces/lieferant-barcode-daten';
import { ProduktBarcodeDaten } from './interfaces/produkt-barcode-daten';
import { RadsatzBarcodeDaten } from './interfaces/radsatz-barcode-daten';

@Injectable({
  providedIn: BarcodeScanModule
})
export class BarcodeVerarbeitungService {
  constructor(private store: Store<AppZustand>) {}

  private istLagerort(code: string): boolean {
    return /^L1\./i.test(code);
  }

  private istLieferant(code: string): boolean {
    return /^A1\./i.test(code);
  }

  private istProdukt(code: string): boolean {
    return /^P1\./i.test(code);
  }

  private istRadsatz(code: string): boolean {
    return code.length == 12;
  }

  private extrahiereLagerortBarcodeDaten(code: string): LagerortBarcodeDaten {
    let treffer = code.match(lagerortBarcodeInhaltsformat);
    if (treffer) {
      return {
        barcode: treffer[1],
        reihe: treffer[2],
        staender: treffer[3],
        fach: treffer[4],
        pruefziffer: treffer[5]
      } as LagerortBarcodeDaten;
    }
  }

  private extrahiereLieferantBarcodeDaten(code: string): LieferantBarcodeDaten {
    let treffer = code.match(lieferantBarcodeInhaltsformat);
    if (treffer) {
      return {
        barcode: treffer[1],
        pruefziffer: treffer[2]
      } as LieferantBarcodeDaten;
    }
  }

  private extrahiereProduktBarcodeDaten(code: string): ProduktBarcodeDaten {
    let treffer = code.match(produktBarcodeInhaltsformat);
    if (treffer) {
      return {
        barcode: treffer[1],
        pruefziffer: treffer[2]
      } as ProduktBarcodeDaten;
    }
  }

  private extrahiereRadsatzDaten(code: string): RadsatzBarcodeDaten {
    return {barcode: code } as RadsatzBarcodeDaten;
  }

  verarbeiteBarcode(code: string) {
    console.log("verarbeite Barcode " + code);

    if (this.istLagerort(code)) {
      let lagerortBarcodeDaten = this.extrahiereLagerortBarcodeDaten(code);
      this.store.dispatch(ladeLagerortdetailsZuBarcode(lagerortBarcodeDaten));
    }

    if (this.istLieferant(code)) {
      let lieferantBarcodeDaten = this.extrahiereLieferantBarcodeDaten(code);
      this.store.dispatch(ladeLieferantdetailsZuBarcode(lieferantBarcodeDaten));
    }

    if (this.istProdukt(code)) {
      let produktBarcodeDaten = this.extrahiereProduktBarcodeDaten(code);
      this.store.dispatch(ladeProduktdetailsZuBarcode(produktBarcodeDaten));
    }

    if (this.istRadsatz(code)) {
      let radsatzBarcodeDaten = this.extrahiereRadsatzDaten(code);
    }

    return null;
  }
}
