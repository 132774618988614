import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReifenartComponent } from './reifenart.component';


const routes: Routes = [
  { path: 'reifenart/:radsatz', component: ReifenartComponent }  // Route für Reifenart mit radsatz-Parameter
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReifenartRoutingModule { }
