import { Component, Input } from '@angular/core';

@Component({
  selector: 'lagermanager-zurueck-symbol',
  templateUrl: './zurueck-symbol.component.html',
  styleUrls: ['./zurueck-symbol.component.scss']
})
export class ZurueckSymbolComponent {
  @Input('class') klassen = '';
}
