import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, ObservableInput } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { backendNichtErreichbar, loescheBackendFehler, logoutBeendet, pruefeAnmeldedatenAbgeschlossen, unerwarteterBackendFehler } from '../backend-zugriff.actions';
import { BackendZugriffModule } from '../backend-zugriff.module';
import { BackendZugriffZustand } from '../interfaces/backend-zugriff-zustand';
import { BenutzerAutorisierung } from '../interfaces/benutzer-autorisierung';

@Injectable({
  providedIn: BackendZugriffModule
})
export class AllgemeineFehlerInterceptor implements HttpInterceptor {
  private behandleFehler(fehler: HttpErrorResponse): ObservableInput<any> {
    if (fehler.error instanceof Error) {
      this.store.dispatch(backendNichtErreichbar());
      return [fehler];
    }
    if (fehler.status === 401) {
      if (fehler.error.benutzernamePasswortInkorrekt) {
        this.store.dispatch(pruefeAnmeldedatenAbgeschlossen({ benutzernamePasswortInkorrekt: true } as BenutzerAutorisierung));
      } else {
        this.store.dispatch(logoutBeendet());
      }
      this.router.navigate(['/'], { relativeTo: this.route.root });
      return [fehler];
    }
    this.store.dispatch(unerwarteterBackendFehler());
    return [fehler];
  }

  intercept(anfrage: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(anfrage).pipe(
      tap(() => this.store.dispatch(loescheBackendFehler())),
      catchError((fehler: HttpErrorResponse) => this.behandleFehler(fehler))
    );
  };

  constructor(private store: Store<BackendZugriffZustand>, private router: Router, private route: ActivatedRoute) {
  }
}
