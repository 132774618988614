import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { AutorisierungService } from '../../backend-zugriff/autorisierung/autorisierung.service';
import { UrlService } from '../../shared/url-service/url.service';
import { RadscanAnfrage } from './interfaces/radscan-anfrage';
import { RadscanErgebnis } from './interfaces/radscan-ergebnis';
import { starteRadscan, radscanAbgeschlossen } from './radscan.actions';

@Injectable()
export class RadscanEffects {
  starteRadscan$ = createEffect(() => this.action$.pipe(
    ofType(starteRadscan), // ofType(starteRadscan.type),
    mergeMap((actionDaten: RadscanAnfrage) => {

      console.log ("radscan.effects createEffect starteRadscan");

      console.log ("radscan.effects URL: " + this.urlService.erstelleUrl(this.autorisierungService.firmenbackendUrl.firmenbackendUrl, 'api/radscan'));

      console.log ("radscan.effects actiondaten: aufNr=" + actionDaten.aufNr);

      return this.http.post<RadscanErgebnis>(this.urlService.erstelleUrl(this.autorisierungService.firmenbackendUrl.firmenbackendUrl, 'api/radscan'), actionDaten).pipe(
        map(ergebnis => {
          console.log("radscan.effects RadscanAbgeschlossen", ergebnis);
          return radscanAbgeschlossen({ ergebnis });
        })
      );

    })
  ));

  constructor(private action$: Actions, private http: HttpClient, private urlService: UrlService, private autorisierungService: AutorisierungService) {}
}
