import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BackendZugriffModule } from '../../backend-zugriff/backend-zugriff.module';
import { BarcodeScanModule } from '../../barcode-scan/barcode-scan.module';
import { SharedModule } from '../../shared/shared.module';
import { RadscanFormularComponent } from './radscan-formular.component';
import { RadscanRoutingModule } from './radscan-routing.module';
import { RadscanEffects } from './radscan.effects';
import { radscanFeatureName, radscanReducer } from './radscan.reducer';

@NgModule({
  declarations: [RadscanFormularComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule,
    BackendZugriffModule,
    BarcodeScanModule,
    ReactiveFormsModule,
    StoreModule.forFeature(radscanFeatureName, radscanReducer),
    EffectsModule.forFeature([RadscanEffects]),
    RadscanRoutingModule
  ]
})
export class RadscanModule { }
