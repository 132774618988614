import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-felgenart',
  templateUrl: './felgenart.component.html',
  styleUrls: ['./felgenart.component.scss']
})
export class FelgenartComponent implements OnInit {
  radsatz: string;
  reifenart: string;
  felgenart: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Lese die übergebenen Query-Parameter (Radsatznummer und Reifenart)
    this.route.queryParams.subscribe(params => {
      this.radsatz = params['radsatz'];
      this.reifenart = params['reifenart'];
    });
  }

  onSubmit(): void {
    console.log(`Gewählte Felgenart: ${this.felgenart}, Radsatz: ${this.radsatz}, Reifenart: ${this.reifenart}`);
    // Hier kannst du weitere Aktionen ausführen, z.B. Daten speichern oder zur nächsten Seite navigieren.

    this.router.navigate(['/reifenhersteller'], {
      queryParams: {
        radsatznummer: this.radsatz,
        reifenart: this.reifenart,
        felgenart: this.felgenart
      }
    });
  }

  // Methode zum Zurückgehen zur Reifenart-Seite
  zurueck(): void {
    // Optional: Navigiere zurück zur Reifenart-Seite
    this.router.navigate(['/reifenart']);
  }
}
