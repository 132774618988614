import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { AutorisierungService } from '../backend-zugriff/autorisierung/autorisierung.service';
import { UrlService } from '../shared/url-service/url.service';
import { ladeLagerortdetailsZuBarcode, ladeLieferantdetailsZuBarcode, ladeProduktdetailsZuBarcode, lagerortdetailsZuBarcodeGeladen, lieferantDetailsZuBarcodeGeladen, produktdetailsZuBarcodeGeladen } from './barcode-scan.actions';
import { LagerortBarcodeDaten } from './interfaces/lagerort-barcode-daten';
import { LagerortDetails } from './interfaces/lagerort-details';
import { LieferantBarcodeDaten } from './interfaces/lieferant-barcode-daten';
import { LieferantDetails } from './interfaces/lieferant-details';
import { ProduktBarcodeDaten } from './interfaces/produkt-barcode-daten';
import { ProduktDetails } from './interfaces/produkt-details';

@Injectable()
export class BarcodeScanEffects {
  ladeLagerortdetailsZuBarcode$ = createEffect(() => this.action$.pipe(
    ofType(ladeLagerortdetailsZuBarcode.type),
    mergeMap((actionDaten: LagerortBarcodeDaten) => {
      var enkodiertesToken = encodeURIComponent(this.autorisierungService.benutzerAutorisierung.token);
      var enkodierterBarcode = encodeURIComponent(actionDaten.barcode);
      return this.httpClient.get<LagerortDetails>(this.urlService.erstelleUrl(this.autorisierungService.firmenbackendUrl.firmenbackendUrl,
          `api/lagerort/${enkodiertesToken}/${enkodierterBarcode}`)).pipe(
        map(ergebnisse => lagerortdetailsZuBarcodeGeladen(ergebnisse))
      );
    })
  ));

  ladeLieferantdetailsZuBarcode$ = createEffect(() => this.action$.pipe(
    ofType(ladeLieferantdetailsZuBarcode.type),
    mergeMap((actionDaten: LieferantBarcodeDaten) => {
      var enkodiertesToken = encodeURIComponent(this.autorisierungService.benutzerAutorisierung.token);
      var enkodierterBarcode = encodeURIComponent(actionDaten.barcode);
      return this.httpClient.get<LieferantDetails>(this.urlService.erstelleUrl(this.autorisierungService.firmenbackendUrl.firmenbackendUrl, `api/lieferant/${enkodiertesToken}/${enkodierterBarcode}`)).pipe(
        map(ergebnisse => lieferantDetailsZuBarcodeGeladen(ergebnisse))
      );
    })
  ));

  ladeProduktdetailsZuBarcode$ = createEffect(() => this.action$.pipe(
    ofType(ladeProduktdetailsZuBarcode.type),
    mergeMap((actionDaten: ProduktBarcodeDaten) => {
      var enkodiertesToken = encodeURIComponent(this.autorisierungService.benutzerAutorisierung.token);
      var enkodierterBarcode = encodeURIComponent(actionDaten.barcode);
      return this.httpClient.get<ProduktDetails>(this.urlService.erstelleUrl(this.autorisierungService.firmenbackendUrl.firmenbackendUrl, `api/produkt/${enkodiertesToken}/${enkodierterBarcode}`)).pipe(
        map(ergebnisse => produktdetailsZuBarcodeGeladen(ergebnisse))
      );
    })
  ));

  constructor(private action$: Actions, private httpClient: HttpClient, private urlService: UrlService, private autorisierungService: AutorisierungService) {

  }
}
