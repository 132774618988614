import { Component, Input } from '@angular/core';

@Component({
  selector: 'lagermanager-user-symbol',
  templateUrl: './user-symbol.component.html',
  styleUrls: ['./user-symbol.component.scss']
})
export class UserSymbolComponent {
  @Input('class') klassen = '';
}
