import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BackendZugriffModule } from '../backend-zugriff/backend-zugriff.module';
// import { BarcodeScanModule } from '../barcode-scan/barcode-scan.module';
import { SharedModule } from '../shared/shared.module';
import { AwtweFormularComponent } from './awtwe-formular/awtwe-formular.component';
import { AwtweRoutingModule } from './awtwe-routing.module';
import { AwtweEffects } from './awtwe.effects';
import { webuchungFeatureName, bewegungsbuchungReducer } from './awtwe.reducer';

@NgModule({
  declarations: [AwtweFormularComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule,
    BackendZugriffModule,
    // BarcodeScanModule,
    ReactiveFormsModule,
    StoreModule.forFeature(webuchungFeatureName, bewegungsbuchungReducer),
    EffectsModule.forFeature([AwtweEffects]),
    AwtweRoutingModule
  ]
})
export class AwtweModule { }
