import { createReducer, on } from '@ngrx/store';
import { AbholungErgebnis } from './interfaces/abholungbuchung-ergebnis';
import { AbholungZustand } from './interfaces/abholungbuchung-zustand';
import { ladeInitialZustand, starteBewegungsbuchung, bewegungsBuchungAbgeschlossen } from './abholung.actions';
import { backendNichtErreichbar, unerwarteterBackendFehler } from '../backend-zugriff/backend-zugriff.actions';

export const AbholungFeatureName = 'abholungbuchung';

export const initialZustand: AbholungZustand = {
  abholungbuchungErgebnis: {
    erfolgreichDurchgefuehrt: false,
    spFehlerStatus: null,
    spFehlermeldung: null
  },
  imZugriff: false
};

function onStarteBewegungsbuchung(zustand: AbholungZustand): AbholungZustand {
  // console.log ("onStarteBewegungsbuchung");

  return {
    ...initialZustand,
    imZugriff: true
  };
}

function onAbholungbuchungAbgeschlossen(zustand: AbholungZustand, actionDaten: AbholungErgebnis): AbholungZustand {
  // console.log ("awtwa.reducer onBewegungsbuchungAbgeschlossen");

  return {
    ...zustand,
    abholungbuchungErgebnis: {
      ...actionDaten,
      erfolgreichDurchgefuehrt: !actionDaten.spFehlerStatus
    },
    imZugriff: false
  };
}

function onLadeInitialZustand(zustand: AbholungZustand): AbholungZustand {
  return initialZustand;
}

function loescheImZugriffFlags(zustand: AbholungZustand): AbholungZustand {
  return {
    ...zustand,
    imZugriff: false
  };
}

const reducer = createReducer(initialZustand,
  on(starteBewegungsbuchung, onStarteBewegungsbuchung),
  on(bewegungsBuchungAbgeschlossen, onAbholungbuchungAbgeschlossen),
  on(ladeInitialZustand, onLadeInitialZustand),
  on(backendNichtErreichbar, loescheImZugriffFlags),
  on(unerwarteterBackendFehler, loescheImZugriffFlags)
);

export function bewegungsbuchungReducer(zustand, actionDaten) {
  // console.log ("awtwa.reducer.ts bewegungsbuchungReducer erzeugt");
  return reducer(zustand, actionDaten);
}
