import { createReducer, on } from '@ngrx/store';
import { VerbrauchsbuchungErgebnis } from './interfaces/verbrauchsbuchung-ergebnis';
import { VerbrauchsbuchungZustand } from './interfaces/verbrauchsbuchung-zustand';
import { ladeInitialZustand, starteVerbrauchsbuchung, verbrauchsbuchungAbgeschlossen } from './verbrauchsbuchung.actions';
import { backendNichtErreichbar, unerwarteterBackendFehler } from '../backend-zugriff/backend-zugriff.actions';

export const verbrauchsbuchungFeatureName = 'verbrauchsbuchung';

export const initialZustand: VerbrauchsbuchungZustand = {
  verbrauchsbuchungErgebnis: {
    erfolgreichDurchgefuehrt: false,
    spFehlerStatus: null,
    spFehlermeldung: null
  },
  imZugriff: false
};

function onStarteVerbrauchsbuchung(zustand: VerbrauchsbuchungZustand): VerbrauchsbuchungZustand {
  return {
    ...initialZustand,
    imZugriff: true
  };
}

function onVerbrauchsbuchungAbgeschlossen(zustand: VerbrauchsbuchungZustand, actionDaten: VerbrauchsbuchungErgebnis): VerbrauchsbuchungZustand {
  return {
    ...zustand,
    verbrauchsbuchungErgebnis: {
      ...actionDaten,
      erfolgreichDurchgefuehrt: !actionDaten.spFehlerStatus
    },
    imZugriff: false
  };
}

function onLadeInitialZustand(zustand: VerbrauchsbuchungZustand): VerbrauchsbuchungZustand {
  return initialZustand;
}

function loescheImZugriffFlags(zustand: VerbrauchsbuchungZustand): VerbrauchsbuchungZustand {
  return {
    ...zustand,
    imZugriff: false
  };
}

const reducer = createReducer(initialZustand,
  on(starteVerbrauchsbuchung, onStarteVerbrauchsbuchung),
  on(verbrauchsbuchungAbgeschlossen, onVerbrauchsbuchungAbgeschlossen),
  on(ladeInitialZustand, onLadeInitialZustand),
  on(backendNichtErreichbar, loescheImZugriffFlags),
  on(unerwarteterBackendFehler, loescheImZugriffFlags)
);

export function verbrauchsbuchungReducer(zustand, actionDaten) {
  return reducer(zustand, actionDaten);
}
