import { createAction, props } from '@ngrx/store';
import { AuslagernAnfrage } from './interfaces/auslagern-anfrage';
import { AuslagernErgebnis } from './interfaces/auslagern-ergebnis';
import { PaletteInfoAnfrage } from './interfaces/paletteinfo-anfrage';
import { PaletteInfoErgebnis } from './interfaces/paletteinfo-ergebnis';

export const starteBewegungsbuchung = createAction('[Auslagern] Starte Auslagern', props<AuslagernAnfrage>());
export const bewegungsBuchungAbgeschlossen = createAction('[Auslagern] Auslagern abgeschlosssen', props<AuslagernErgebnis>());
export const ladeInitialZustand = createAction('[Auslagern] Lade Initialzustand');

export const startePaletteInfoAnfrage = createAction('[Info] Starte PaletteInfo', props<PaletteInfoAnfrage>()); // AuslagernAnfrage
export const paletteinfoGeladen = createAction('[Info] PaletteInfo geladen', props<PaletteInfoErgebnis>()); // AuslagernErgebnis

