import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BackendZugriffModule } from '../backend-zugriff/backend-zugriff.module';
// import { BarcodeScanModule } from '../barcode-scan/barcode-scan.module';
import { SharedModule } from '../shared/shared.module';
import { AwtauslagernFormularComponent } from './awtauslagern-formular/awtauslagern-formular.component';
import { AwtauslagernRoutingModule } from './awtauslagern-routing.module';
import { AwtauslagernEffects } from './awtauslagern.effects';
import { auslagenFeatureName, bewegungsbuchungReducer } from './awtauslagern.reducer'; // paletteinfoFeatureName, paletteinfoReducer
// import { waehlePaletteinfoFeature } from './awtauslagern.selectors';

@NgModule({
  declarations: [AwtauslagernFormularComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule,
    BackendZugriffModule,
    // BarcodeScanModule,
    ReactiveFormsModule,
    StoreModule.forFeature(auslagenFeatureName, bewegungsbuchungReducer ),
    // StoreModule.forFeature(paletteinfoFeatureName, paletteinfoReducer ),
    EffectsModule.forFeature([AwtauslagernEffects]),
    AwtauslagernRoutingModule
  ]
})
export class AwtauslagernModule { }
