import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-reifenart',
  templateUrl: './reifenart.component.html',
  styleUrls: ['./reifenart.component.scss']
})
export class ReifenartComponent implements OnInit {
  radsatz: string;
  reifenart: string = '';  // Standardmäßig auf Sommerreifen gesetzt


  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Radsatz-Wert aus der Route lesen
    this.radsatz = this.route.snapshot.paramMap.get('radsatz');
  }

  // Methode für den Submit-Button
  onSubmit(form): void {
    if (form.valid) {
      console.log(`Radsatz: ${this.radsatz}, Gewählte Reifenart: ${this.reifenart}`);

      // Navigiere zur Felgenart-Seite und übergebe Radsatznummer und Reifenart als Query-Params
      this.router.navigate(['/felgenart'], {
        queryParams: {
          radsatz: this.radsatz,
          reifenart: this.reifenart
        }
      });
    }
  }

  // Methode zum Zurückgehen zur Radscan-Seite
  zurueck(): void {
    this.router.navigate(['/radscan']);
  }
}
