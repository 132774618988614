import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dot',
  templateUrl: './dot.component.html',
  styleUrls: ['./dot.component.scss']
})
export class DotComponent implements OnInit {
  radsatznummer: string;
  reifenart: string;
  felgenart: string;
  reifenhersteller: string;
  reifenprofiltyp: string;
  dotCode: string = '';

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    // Lese die Query-Params aus der Route
    this.route.queryParams.subscribe(params => {
      this.radsatznummer = params['radsatznummer'];
      this.reifenart = params['reifenart'];
      this.felgenart = params['felgenart'];
      this.reifenhersteller = params['reifenhersteller'];
      this.reifenprofiltyp = params['reifenprofiltyp'];
    });
  }

  // Optional: Methode zur Verarbeitung des DOT-Codes
  onSubmit(): void {
    console.log('DOT Code:', this.dotCode);
    console.log('Übergebene Parameter:', this.radsatznummer, this.reifenart, this.felgenart, this.reifenhersteller, this.reifenprofiltyp);
    // Weitere Logik zum Absenden des Formulars oder Navigation
  }
}
