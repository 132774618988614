import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppZustand } from '../shared/interfaces/app-zustand';
import { AbholungZustand } from './interfaces/abholungbuchung-zustand';
import { AbholungFeatureName } from './abholung.reducer';
//import { AbholungErgebnis } from './interfaces/abholungbuchung-ergebnis';
//import { AbholungZustand } from './interfaces/abholungbuchung-zustand';

export const waehleAbholungsbuchungFeature = createFeatureSelector<AppZustand, AbholungZustand>(AbholungFeatureName);

export const waehleBewegungsbuchungErgebnis = createSelector(
  waehleAbholungsbuchungFeature,
  zustand => zustand.abholungbuchungErgebnis
);

export const waehleImZugriff = createSelector(
  waehleAbholungsbuchungFeature,
  zustand => zustand.imZugriff
);
