import { Component, OnDestroy, OnInit, ViewChild, ElementRef, AfterViewInit  } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { lagerortBarcodeInhaltsformat, lieferantBarcodeInhaltsformat, produktBarcodeInhaltsformat } from 'src/app/shared/konstanten';
import { AutorisierungService } from '../../backend-zugriff/autorisierung/autorisierung.service';
import { ladeInitialZustand as ladeInitialZustandBarcodeScan } from '../../barcode-scan/barcode-scan.actions';
// Barcode-SCAN: import { waehleLagerortDetails, waehleLagerortImZugriff, waehleLieferantDetails, waehleLieferantImZugriff, waehleProduktDetails, waehleProduktImZugriff } from '../../barcode-scan/barcode-scan.selectors';
import { BarcodeVerarbeitungService } from '../../barcode-scan/barcode-verarbeitung.service';
import { LagerortDetails } from '../../barcode-scan/interfaces/lagerort-details';
import { LieferantDetails } from '../../barcode-scan/interfaces/lieferant-details';
import { ProduktDetails } from '../../barcode-scan/interfaces/produkt-details';
import { ScanFormularComponent } from '../../barcode-scan/scan-formular/scan-formular.component';
import { KonfigurationService } from '../../konfiguration/konfiguration.service';
import { AppZustand } from '../../shared/interfaces/app-zustand';
import { datenBekanntValidierer } from '../../shared/validierer/daten-bekannt-validierer';
import { zahlValidierer } from '../../shared/validierer/zahl-validierer';
import { BewegungsbuchungAnfrage } from '../interfaces/bewegungssbuchung-anfrage';
import { BewegungsbuchungErgebnis } from '../interfaces/bewegungsbuchung-ergebnis';
import { BewegungsbuchungZustand } from '../interfaces/bewegungsbuchung-zustand';
import { ladeInitialZustand as ladeInitialZustandBewegungsbuchung, starteBewegungsbuchung } from '../awteinlagern.actions';
import { waehleImZugriff as waehleBewegungsbuchungImZugriff, waehleBewegungsbuchungFeature } from '../awteinlagern.selectors';
import { Router, ActivatedRoute } from '@angular/router';

import { starteImage } from '../../image/image.actions';
import { ImageAnfrage } from '../../image/interfaces/image-anfrage';


@Component({
  selector: 'app-awteinlagern-formular',
  templateUrl: './awteinlagern-formular.component.html',
  styleUrls: ['./awteinlagern-formular.component.scss']
})

export class AwteinlagernFormularComponent implements OnInit, OnDestroy, AfterViewInit {
  private observableBeobachter: Subscription[] = [];

  @ViewChild(ScanFormularComponent, { static: false })
  private scanFormularComponent: ScanFormularComponent;

  @ViewChild('meldungen', { static: false })
  private meldungen;

  @ViewChild('focusAuftragsnummer', { static: false }) focusAuftragsnummer: ElementRef;
  @ViewChild('focusPalette', { static: false }) focusPalette: ElementRef;

  // Ein ElementRef-Objekt, um das hochgeladene Bild gleich anzeigen zu können.
  @ViewChild('ziel', { static: false}) ziel: ElementRef;


  get istScanAktiv() {
    return this.scanFormularComponent && this.scanFormularComponent.istScanAktiv;
  }

  bewegungsbuchungImZugriff: boolean;
  private bewegungsbuchungErgebnisDaten: BewegungsbuchungErgebnis = {
    erfolgreichDurchgefuehrt: false,
    spFehlerStatus: null,
    spFehlermeldung: null
  };


  get bewegungsbuchungErgebnis() {
    return this.bewegungsbuchungErgebnisDaten;
  }


  formular: FormGroup;


  get auftragsnummer() {
    return this.formular.get('auftragsnummer');
  }

  get palette() {
    return this.formular.get('palette');
  }

  get bemerkung() {
    return this.formular.get('bemerkung');
  }


  constructor(private barcodeVerarbeitungService: BarcodeVerarbeitungService, private formBuilder: FormBuilder, private store: Store<AppZustand>,
    private konfigurationService: KonfigurationService, private autorisierungService: AutorisierungService, private modalService: NgbModal,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    // hier ergänzen wenn neue Felder mit formControlName aufgenommen werden
    this.formular = this.formBuilder.group({
      auftragsnummer: new FormControl(''),
      palette: new FormControl(''),
      bemerkung: new FormControl(''),
      ziel: new FormControl('')
    });

    let BewegungsbuchungImZugriff$ = this.store.select(waehleBewegungsbuchungImZugriff);
    let BewegungsbuchungFeature$ = this.store.select(waehleBewegungsbuchungFeature);


    this.observableBeobachter.push(
      BewegungsbuchungFeature$.subscribe(ergebnis => this.verarbeiteBewegungsbuchungFeature(ergebnis))
    );

    this.observableBeobachter.push(
      BewegungsbuchungImZugriff$.subscribe(ergebnis => {
        this.aktiviereFormular(ergebnis);
      })
    );

  }


  ngAfterViewInit() { // Focus in erstes Feld setzen
    this.focusAuftragsnummer.nativeElement.focus();
  }

  fokussierePalette(event) {
    // console.log("awteinlagern-formular.component.ts fokussierePalette");

    event.preventDefault();
    this.focusPalette.nativeElement.focus();
    return false;
  }

  private leereBewegungsbuchungErgebnis(): void {
    this.bewegungsbuchungErgebnis.erfolgreichDurchgefuehrt = false;
    this.bewegungsbuchungErgebnis.spFehlerStatus = null;
    this.bewegungsbuchungErgebnis.spFehlermeldung = null;
  }

  private oeffneMeldungenDialog() {
    // console.log ("awteinlagern-formular.component oeffneMeldungenDialog" + this.meldungen);
    this.modalService.open(this.meldungen, { scrollable: true, centered: true });

    this.focusAuftragsnummer.nativeElement.focus(); // funktioniert hier nicht
  }

  /*
  private zusamenfasserImZugriffUndLagerortDetails(imZugriff: boolean, lagerortDetails: LagerortDetails) {
    return {
      imZugriff: imZugriff,
      lagerortDetails: lagerortDetails
    };
  }


  private zusammenfasserImZugriffUndProduktDetails(imZugriff: boolean, produktDetails: ProduktDetails) {
    return {
      imZugriff: imZugriff,
      produktDetails: produktDetails
    };
  }
*/

  private aktiviereFormular(imZugriff: boolean) {
    this.bewegungsbuchungImZugriff = imZugriff;
    if (imZugriff) {
      // this.formular.disable({ emitEvent: false });
      // RD 20200722 wir sperren das Formular einfach nie, damit Focus weiterhin funktioniert
      this.formular.enable({ emitEvent: false });
    } else {
      this.formular.enable({ emitEvent: false });
    }
  }

  ngOnDestroy() {
    this.observableBeobachter.forEach(beobachter => beobachter.unsubscribe());
    this.store.dispatch(ladeInitialZustandBarcodeScan());
    this.ladeInitialZustandBewegungsbuchung();
  }

  scanStarten() {
    this.leereBewegungsbuchungErgebnis();
    this.scanFormularComponent.scanStarten();
  }

  barcodeGescannt(code: string) {
    this.barcodeVerarbeitungService.verarbeiteBarcode(code);
    this.ladeInitialZustandBewegungsbuchung();
  }



  // Der Eventhandler, der bei Auswahl eines Bilds feuert.
  imageGeaendert(event) {
    console.log('imageGeaendert', event);

    // event.target.files ist die Liste der ausgewählten Dateien.
    let fileList = event.target.files;

    if (fileList && fileList.length && fileList[0].type.match(/^image\//)) {
      // Mit dem FileReader werden die Daten des ausgewählten Bilds ausgelesen.
      // Momentan werden die Daten als Data-URL gelesen.
      // FileReader benutzt dabei selbst das Event onload, mit dem es anzeigt,
      // dass die Datei komplett geladen wurde.
      let fileReader = new FileReader();

      fileReader.onload = (fileReaderEvent) => {
        console.log('fileReaderEvent', fileReaderEvent);
        //let originalData = fileReaderEvent.target.result;

        //var fileUrl = imgsrc.target.result; //change to
        var originalData = (fileReaderEvent.target as FileReader).result; //cast to correct type -- https://dev.to/hasanin19salah/answer-new-typescript-1-8-4-build-error-build-property-result-does-not-exist-on-type-eventtarget-1ebn

        // Die Prüfung auf string wird vorgenommen, damit TypeScript keinen Typfehler bei .split() meldet.
        if (typeof originalData === 'string') {
          this.ziel.nativeElement.src = originalData;

          // Hier wird die Data-URL zerteilt, um den Base64-Anteil zu extrahieren.
          // Das muss aber nicht clientseitig gemacht werden. Eventuell kann die
          // gesamte Data-URL in der Datenbank gespeichert werden, wenn keine
          // serverseitige Verarbeitung des Bilds notwendig ist.
          let data = originalData.split(',')[1];

          // Die Base64-Daten werden an den Server geschickt.
          let anfrage: ImageAnfrage = {
            imageBase64: data,
            auftrag: this.auftragsnummer.value // "1000000001"
          };

          this.store.dispatch(starteImage(anfrage))
        }
      }

      fileReader.readAsDataURL(fileList[0]);
    }
  }



  buchen(formulardaten) {
    this.ladeInitialZustandBewegungsbuchung();

    // console.log("starte buchen" + formulardaten);

    if (this.formular.invalid) {
      // console.log("buchen invalid!");

      this.auftragsnummer.markAsDirty();
      this.palette.markAsDirty();
      this.bemerkung.markAsDirty();
      this.oeffneMeldungenDialog();

      return;
    }


    let buchungsdatum = new Date(); // Typescript Datum ist UTC, wir müssen in GMT+2 umrechnen:
    buchungsdatum = new Date( buchungsdatum.getTime() + (buchungsdatum.getTimezoneOffset() * 1000) );
    // console.log("buchen Zeit " + buchungsdatum.toString() );

    let anfrage: BewegungsbuchungAnfrage = {
      token: this.autorisierungService.benutzerAutorisierung.token,
      aufNr: this.auftragsnummer.value,
      BewaID: -3,
      Rollwagen: this.palette.value,
      Kennzeichen: '',
      Benutzer: this.autorisierungService.benutzerAutorisierung.anzeigename,
      RadAnzahl: 4,
      Daten: 'Auftrag| AU1_Bemerkung=\'' + this.bemerkung.value + '\' ',
      paramHaendler_ID: 0,
      bewd_Zeit: buchungsdatum
    };

    console.log("buchen BewegungsbuchungAnfrage erzeugt");

    this.store.dispatch(starteBewegungsbuchung(anfrage));
  }

  private verarbeiteBewegungsbuchungFeature(ergebnis: BewegungsbuchungZustand) {
    // console.log("awteinlagern-formular.component.ts verarbeiteBewegungsbuchungFeature imZugriff: " + ergebnis.imZugriff + " erfolgreichDurchgefuehrt: " + ergebnis.bewegungsbuchungErgebnis.erfolgreichDurchgefuehrt);

    if (!ergebnis.imZugriff) {
      if (ergebnis.bewegungsbuchungErgebnis.erfolgreichDurchgefuehrt) {

        this.auftragsnummer.setValue('');
        this.palette.setValue('');
        this.bemerkung.reset(); // RD 20200709 Schaden zurücksetzen  this.defaultState

        this.ziel = null; // Bild zurücksetzen
        //this.ziel.nativeElement.value = "";
        //this.ziel.nativeElement.value = null;
        //this.ziel.nativeElement.image = null;

        //this.ziel.nativeElement.img = null;

        this.formular.markAsUntouched();
        this.formular.markAsPristine();

        // "Die Bewegung wurde erfolgreich gebucht."

        this.oeffneMeldungenDialog();

        this.focusAuftragsnummer.nativeElement.focus(); // funktioniert hier nicht

      } else if (ergebnis.bewegungsbuchungErgebnis.spFehlerStatus != null) {
        this.oeffneMeldungenDialog();
      }
      this.bewegungsbuchungErgebnisDaten = ergebnis.bewegungsbuchungErgebnis;
    }
  }

  private ladeInitialZustandBewegungsbuchung() {
    this.store.dispatch(ladeInitialZustandBewegungsbuchung());
  }

  geheZurueckZurNavigation() {
    this.router.navigate(['/navigation'], { relativeTo: this.route.root});
  }
}

