import { BrowserBarcodeReader, BrowserCodeReader, ChecksumException, Exception, FormatException, NotFoundException, Result } from '@zxing/library';
import { Subject } from 'rxjs';
import { BarcodeScanKonfiguration } from './interfaces/barcode-scan-konfiguration';
import { BarcodeScanner } from './interfaces/barcode-scanner';

export class ZxingScanner implements BarcodeScanner {
  private ergebnisLieferant: Subject<string>;
  private konfiguration: BarcodeScanKonfiguration;
  private reader: BrowserCodeReader;

  initialisiereScanner(neueKonfiguration: BarcodeScanKonfiguration) {
    this.konfiguration = neueKonfiguration;
  }

  starteScan() {
    this.ergebnisLieferant = new Subject<string>();

    this.reader = new BrowserBarcodeReader();
    this.reader.timeBetweenDecodingAttempts = 5;

    let constraints = {
      video: {
        width: 1920,
        height: 1080,
        torch: true,
        facingMode: 'environment'
      }
    };


    this.reader
      .decodeFromConstraints(constraints, this.konfiguration.videoElementId,
        (result: Result, error?: Exception) => {
          if (error &&
            !(error instanceof ChecksumException ||
              error instanceof FormatException ||
              error instanceof NotFoundException)) {
            this.ergebnisLieferant.error(error);
            this.ergebnisLieferant.complete();

            console.log("zxing-scanner.decodeFromConstraints error");
          } else if (result) {
            if (this.konfiguration.erwarteteInhaltsformate && this.konfiguration.erwarteteInhaltsformate.length > 0) {
              this.konfiguration.erwarteteInhaltsformate.forEach((regEx) => {
                console.log("zxing-scanner.ts.decodeFromConstraints " + regEx + " - " + result.getText());

                if (regEx.test(result.getText())) {
                  this.reader.reset();

                  this.ergebnisLieferant.next(result.getText());
                  this.ergebnisLieferant.complete();
                }
              });
            }
          }
        });

    return this.ergebnisLieferant.asObservable();
  }

  stoppeScan() {
    this.reader.reset();
    this.ergebnisLieferant.complete();
  }
}
