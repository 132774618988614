import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UrlService } from '../../shared/url-service/url.service';
import { AutorisierungService } from '../../backend-zugriff/autorisierung/autorisierung.service';

@Component({
  selector: 'app-reifenprofiltyp',
  templateUrl: './reifenprofiltyp.component.html',
  styleUrls: ['./reifenprofiltyp.component.scss']
})
export class ReifenprofiltypComponent implements OnInit {

  private apiUrl = "";

  radsatznummer: string;
  reifenart: string;
  felgenart: string;
  reifenhersteller: string;

  suchbegriff: string = '';
  profiltypen: any[] = [];
  gefilterteProfiltypen: any[] = [];

  constructor(private route: ActivatedRoute, private http: HttpClient,
    private action$: Actions, private urlService: UrlService, private autorisierungService: AutorisierungService,
    private router: Router
  ) {
    console.log("reifenhersteller.service constructor");

    this.apiUrl = this.urlService.erstelleUrl(this.autorisierungService.firmenbackendUrl.firmenbackendUrl,
      'api/reifenprofiltyp');
    console.log ("apiUrl = " + this.apiUrl);
  }


  ngOnInit(): void {
    // Lese die Query-Parameter (Radsatznummer, Reifenart, Felgenart, Reifenhersteller) aus der Route
    this.route.queryParams.subscribe(params => {
      this.radsatznummer = params['radsatznummer'];
      this.reifenart = params['reifenart'];
      this.felgenart = params['felgenart'];
      this.reifenhersteller = params['reifenhersteller'];
    });

    // Lade die Reifenprofiltypen für den Reifenhersteller vom Backend
    this.ladeProfiltypen();
  }

  // Methode zum Laden der Profiltypen für den Reifenhersteller
  ladeProfiltypen(): void {
    console.log ("ladeProfiltypen");
console.log("apiURL = " + this.apiUrl);

    this.http.get<any[]>(this.apiUrl + `?reifenhersteller=${this.reifenhersteller}`)
      .subscribe((data) => {
        this.profiltypen = data;
        this.gefilterteProfiltypen = data; // Initial zeige alle Profiltypen
      }, (error) => {
        console.error('Fehler beim Laden der Reifenprofiltypen:', error);
      });
  }

  // Filtere die Liste der Profiltypen basierend auf dem Suchbegriff
  filterProfiltypen(): void {
    const filter = this.suchbegriff.toLowerCase();
    this.gefilterteProfiltypen = this.profiltypen.filter(profiltyp =>
      profiltyp.fabrikat.toLowerCase().includes(filter)
    );
  }

  // Navigiere zur DOT-Seite bei Auswahl eines Profiltyps
  waehleProfiltyp(profiltyp: string): void {
    this.router.navigate(['/dimension'], {
      queryParams: {
        radsatznummer: this.radsatznummer,
        reifenart: this.reifenart,
        felgenart: this.felgenart,
        reifenhersteller: this.reifenhersteller,
        reifenprofiltyp: profiltyp
      }
    });
  }

  // Methode zum Zurückgehen zur Radscan-Seite
  zurueck(): void {
    this.router.navigate(['/radscan']);
  }
}
