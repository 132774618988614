import { Component, Input } from '@angular/core';

@Component({
  selector: 'lagermanager-umlagern-symbol',
  templateUrl: './umlagern-symbol.component.html',
  styleUrls: ['./umlagern-symbol.component.scss']
})
export class UmlagernSymbolComponent {
  @Input('class') klassen = '';
}
