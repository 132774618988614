import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BackendZugriffModule } from '../backend-zugriff/backend-zugriff.module';
import { BarcodeScanModule } from '../barcode-scan/barcode-scan.module';
import { SharedModule } from '../shared/shared.module';
import { VerbrauchsbuchungFormularComponent } from './verbrauchsbuchung-formular/verbrauchsbuchung-formular.component';
import { VerbrauchsbuchungRoutingModule } from './verbrauchsbuchung-routing.module';
import { VerbrauchsbuchungEffects } from './verbrauchsbuchung.effects';
import { verbrauchsbuchungFeatureName, verbrauchsbuchungReducer } from './verbrauchsbuchung.reducer';

@NgModule({
  declarations: [VerbrauchsbuchungFormularComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule,
    BackendZugriffModule,
    BarcodeScanModule,
    ReactiveFormsModule,
    StoreModule.forFeature(verbrauchsbuchungFeatureName, verbrauchsbuchungReducer),
    EffectsModule.forFeature([VerbrauchsbuchungEffects]),
    VerbrauchsbuchungRoutingModule
  ]
})
export class VerbrauchsbuchungModule { }
