import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReifenartComponent } from './reifenart.component';
import { ReifenartRoutingModule } from './reifenart-routing.module';
import { FormsModule } from '@angular/forms';  // Importiere FormsModule hier


@NgModule({
  declarations: [ReifenartComponent],
  imports: [
    CommonModule,
    ReifenartRoutingModule,
    FormsModule
  ]
})
export class ReifenartModule { }
