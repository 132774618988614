import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import { BarcodeScanEffects } from './barcode-scan.effects';
import { barcodeScanFeatureName, barcodeScanReducer } from './barcode-scan.reducer';
import { ScanFormularComponent } from './scan-formular/scan-formular.component';

@NgModule({
  declarations: [
    ScanFormularComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    StoreModule.forFeature(barcodeScanFeatureName, barcodeScanReducer),
    EffectsModule.forFeature([BarcodeScanEffects])
  ],
  exports: [
    ScanFormularComponent
  ]
})
export class BarcodeScanModule { }
