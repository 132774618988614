import { ImageZustand } from './interfaces/image-zustand';
import { ImageErgebnis } from './interfaces/image-ergebnis';
import { createReducer, on } from '@ngrx/store';
import { imageAbgeschlossen, starteImage } from './image.actions';

export const imageFeatureName = 'image';

export const initialZustand: ImageZustand = {
  imageErgebnis: {
    erfolgreichDurchgefuehrt: false,
    spFehlerStatus: null,
    spFehlermeldung: null
  },
  imZugriff: false
};

function onStarteImage(zustand: ImageZustand): ImageZustand {
  return {
    ...initialZustand,
    imZugriff: true
  };
}

function onImageAbgeschlossen(zustand: ImageZustand, actionDaten: ImageErgebnis): ImageZustand {
  return {
    ...zustand,
    imageErgebnis: {
      ...actionDaten,
      erfolgreichDurchgefuehrt: !actionDaten.spFehlerStatus
    },
    imZugriff: false
  };
}

const reducer = createReducer(initialZustand,
  on(starteImage, onStarteImage),
  on(imageAbgeschlossen, onImageAbgeschlossen)
);

export function imageReducer(zustand, actionDaten) {
  return reducer(zustand, actionDaten);
}
