import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReifenherstellerComponent } from './reifenhersteller.component';  // Importiere die FelgenartComponent

const routes: Routes = [
  { path: 'reifenhersteller', component: ReifenherstellerComponent },  // Definiere die Route für Felgenart
];

@NgModule({
  imports: [RouterModule.forChild(routes)],  // Verwende `forChild` für Feature-Routing
  exports: [RouterModule]
})
export class ReifenherstellerRoutingModule { }
