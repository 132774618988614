import { createAction, props } from '@ngrx/store';
import { AnmeldedatenMitFirmenbackendUrl } from './interfaces/anmeldedaten-mit-firmenbackend-url';
import { BenutzerAutorisierung } from './interfaces/benutzer-autorisierung';
import { FirmenbackendUrlAnfrage } from './interfaces/firmenbackend-url-anfrage';
import { FirmenbackendUrlErgebnis } from './interfaces/firmenbackend-url-ergebnis';

export const beginneLogin = createAction('[BackendZugriff] Beginne Login');
export const loginBeendet = createAction('[BackendZugriff] Login beendet');

export const ermittleFirmenbackendUrl = createAction('[BackendZugriff] Ermittle Firmenbackend-URL', props<FirmenbackendUrlAnfrage>());
export const ermittleFirmenbackendUrlAbgeschlossen = createAction('[BackendZugriff] Ermittle Firmenbackend-URL erfolgreich', props<FirmenbackendUrlErgebnis>());

export const pruefeAnmeldedaten = createAction('[BackendZugriff] Prüfe Anmeldedaten', props<AnmeldedatenMitFirmenbackendUrl>());
export const pruefeAnmeldedatenAbgeschlossen = createAction('[BackendZugriff] Prüfe Anmeldedaten abgeschlossen', props<BenutzerAutorisierung>());

export const backendNichtErreichbar = createAction('[BackendZugriff] Backend nicht erreichbar');
export const unerwarteterBackendFehler = createAction('[BackendZugriff] Unerwarteter Backendfehler');
export const loescheBackendFehler = createAction('[BackendZugriff] Lösche Backendfehler');

export const loggeAus = createAction('[BackendZugriff] Logge aus');
export const logoutBeendet = createAction('[BackendZugriff] Logout beendet');
