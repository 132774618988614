import { Component, OnInit } from '@angular/core';
import { ReifenherstellerService } from './reifenhersteller.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reifenhersteller',
  templateUrl: './reifenhersteller.component.html',
  styleUrls: ['./reifenhersteller.component.scss']
})
export class ReifenherstellerComponent implements OnInit {

  radsatznummer: string;
  reifenart: string;
  felgenart: string;

  // Herstellerliste vom Backend
  herstellerListe: any[] = [];


  // Gefilterte Liste, die basierend auf der Benutzereingabe aktualisiert wird
  gefilterteHerstellerListe: any[] = [];

  // Suchbegriff für die Filterung
  suchbegriff: string = '';

  constructor(private reifenherstellerService: ReifenherstellerService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    // Herstellerliste vom Backend laden
    this.reifenherstellerService.getReifenhersteller().subscribe(
      (hersteller: any[]) => {
        this.herstellerListe = hersteller;
        this.gefilterteHerstellerListe = hersteller;  // Initial alle Hersteller anzeigen
      },
      (error) => {
        console.error('Fehler beim Laden der Herstellerliste', error);
      }
    );


    // Lese die Query-Params (Radsatznummer, Reifenart, Felgenart) aus der Route
    this.route.queryParams.subscribe(params => {
      this.radsatznummer = params['radsatznummer'];
      this.reifenart = params['reifenart'];
      this.felgenart = params['felgenart'];
    });

  }

  // Filtert die Liste der Hersteller basierend auf dem Suchbegriff
  filterHersteller(): void {
    const filter = this.suchbegriff.toLowerCase();
    this.gefilterteHerstellerListe = this.herstellerListe.filter(hersteller =>
      hersteller.hsName.toLowerCase().includes(filter)
    );
  }

  // Navigiere zur Reifenprofiltyp-Seite bei Auswahl eines Herstellers
  waehleHersteller(hersteller: any): void {
    this.router.navigate(['/reifenprofiltyp'], {
      queryParams: {
        radsatznummer: this.radsatznummer,
        reifenart: this.reifenart,
        felgenart: this.felgenart,
        reifenhersteller: hersteller.hsName
      }
    });
  }

  // Methode zum Zurückgehen
  zurueck(): void {
    this.router.navigate(['/felgenart']);
  }
}
