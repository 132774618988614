import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutorisierungGuard } from '../backend-zugriff/autorisierung/autorisierung.guard';
import { AwtwaFormularComponent } from './awtwa-formular/awtwa-formular.component';

const routes: Routes = [
  { path: 'awtwa', component: AwtwaFormularComponent, canActivate: [AutorisierungGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AwtwaRoutingModule { }
