import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppZustand } from 'src/app/shared/interfaces/app-zustand';
import { waehleBackendZugriffFehler } from '../backend-zugriff.selectors';
import { BackendZugriffFehler } from '../interfaces/backend-zugriff-fehler';

@Component({
  selector: 'lagermanager-backend-fehler-anzeige',
  templateUrl: './backend-fehler-anzeige.component.html',
  styleUrls: ['./backend-fehler-anzeige.component.scss']
})
export class BackendFehlerAnzeigeComponent implements OnInit, OnDestroy {
  @ViewChild('meldungen', { static: false })
  private meldungen;

  private observableBeobachter: Subscription[] = [];

  backendZugriffFehler: BackendZugriffFehler;

  constructor(private store: Store<AppZustand>, private modalService: NgbModal) { }

  ngOnInit() {
    this.observableBeobachter.push(
      this.store.select(waehleBackendZugriffFehler).subscribe(wert => {
        this.backendZugriffFehler = wert;

        if (this.backendZugriffFehler.backendNichtErreichbar || this.backendZugriffFehler.unerwarteterBackendFehler) {
          this.modalService.open(this.meldungen, { scrollable: true, centered: true });
        }
      })
    );
  }

  ngOnDestroy() {
    this.observableBeobachter.forEach(beobachter => beobachter.unsubscribe());
  }
}
